import React, { useState, useEffect } from 'react';
import { callApi, HttpMethod } from '../../../services/apiService';
import { Link } from 'react-router-dom';
import Loading from '../../../components/Loading';

const IsNotLoggedIn: React.FC = () => {
  const [topics, setTopics] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    async function ShowTopics() {
      try {
        let apiUrl = `ComplianceGuide/Topics`;
        const fetchedData = await callApi(apiUrl, HttpMethod.GET);
        setTopics(fetchedData);
        setIsLoading(false);
      } catch (err) {
        const errorMessage = (err as Error).message;
        console.log(errorMessage);
        setIsLoading(false); // Set loading to false in case of error to not leave the user in perpetual loading state
      }
    }

    ShowTopics();
  }, []);

  return (
    <>
      <h3>Summary Charts for Legal Telemarketing Compliance</h3>
      <div>
        Allows you to select one or more of the following regulatory topics and view summary information for any number of states or view key information in a chart format. Each regulatory rule provides a citation which links directly to the specific paragraph in the full text of the state statutes.
      </div>
      {isLoading ? (
        <Loading />
      ) : (
        <ul>
          {topics.map((topic, index) => (
            <li key={index}>{topic.topic}</li>
          ))}
        </ul>
      )}

      <div style={{ backgroundColor: 'grey', padding: 15, borderRadius: 5 }}>
        <h3>Account required</h3>
        <p>
          Please <Link to="/login">log in</Link> to gain access to this resource.
        </p>

      </div>

    </>
  );
}

export default IsNotLoggedIn;
