import Cookies from 'js-cookie';

export enum HttpMethod {
  GET = "GET",
  POST = "POST",
}

export async function callApi(
  endpoint: string,
  method: HttpMethod,
  body?: any,
  redirect: boolean = true,
  contentType?: string, 
): Promise<any> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const headers = new Headers();
  headers.append("Content-Type", contentType ?? "application/json");

  const loginId = Cookies.get('loginId');
  const csrfToken = Cookies.get('csrfToken');
  if (loginId) {
    headers.append('loginId', loginId);
  }
  if (csrfToken) {
    headers.append('csrfToken', csrfToken);
  }

  const sanitizedEndpoint = endpoint.startsWith('/') ? endpoint.slice(1) : endpoint;

  const requestOptions: RequestInit = {
    method: method,
    headers: headers,
    body: body ? JSON.stringify(body) : undefined,
    credentials: 'include', // Ensure cookies are sent with cross-origin requests
  };

  const response = await fetch(`${baseUrl}/${sanitizedEndpoint}`, requestOptions);
  if (!response.ok) {
    const responseData = await response.json();
    if (responseData.message && responseData.message === "Unauthorized") {
      throw new Error("Unauthorized");
    } else {
      throw new Error(responseData.message || "An error occurred");
    }
  }

  return response.json();
}
