import React from 'react';
import { useWizard } from '../../../contexts/ComplianceGuide/ComplianceWizardContext';
import CWQuestion from './CWQuestion';

const Step2: React.FC = () => {
    const { answers, setAnswers, markStepAsComplete } = useWizard();

    const handleAnswerChange = (column: string, value: any) => {
        setAnswers(prev => ({ ...prev, [column]: value }));

        const updatedAnswers = { ...answers, [column]: value };
        if (areRequiredQuestionsAnswered(updatedAnswers)) {
            markStepAsComplete(2, true);
        } else {
            markStepAsComplete(2, false);
        }
    };

    const areRequiredQuestionsAnswered = (updatedAnswers: Record<string, any>) => {
        const requiredQuestions = ['inquiryEBR', 'transactionEBR', 'permissionEBR', 'prize', 'monitoring'];
    
        if (updatedAnswers.callDirection === 'outbound' || updatedAnswers.callDirection === 'both') {
            requiredQuestions.push('predictiveDialer', 'prerecorded', 'outboundUpsell');
        }
        if (updatedAnswers.callDirection === 'inbound' || updatedAnswers.callDirection === 'both') {
            requiredQuestions.push('inboundUpsell');
        }
    
        return requiredQuestions.every(q => updatedAnswers[q] !== undefined && updatedAnswers[q] !== '');
    };

    // useEffect(() => {
    //     if (areRequiredQuestionsAnswered()) {
    //         markStepAsComplete(2, true);
    //     } else {
    //         markStepAsComplete(2, false);
    //     }
    // }, [answers, markStepAsComplete]);

    return (
        <>
            {(answers.callDirection?.toLowerCase() === 'outbound' || answers.callDirection?.toLowerCase() === 'both') && (
                <CWQuestion
                    column="predictiveDialer"
                    value={answers["predictiveDialer"] || ""}
                    onChange={handleAnswerChange}
                />
            )}
            <CWQuestion
                column="inquiryEBR"
                value={answers["inquiryEBR"] || ""}
                onChange={handleAnswerChange}
            />
            <CWQuestion
                column="transactionEBR"
                value={answers["transactionEBR"] || ""}
                onChange={handleAnswerChange}
            />
            <CWQuestion
                column="permissionEBR"
                value={answers["permissionEBR"] || ""}
                onChange={handleAnswerChange}
            />
            {(answers.callDirection?.toLowerCase() === 'outbound' || answers.callDirection?.toLowerCase() === 'both') && (
                <CWQuestion
                    column="prerecorded"
                    value={answers["prerecorded"] || ""}
                    onChange={handleAnswerChange}
                />
            )}
            <CWQuestion
                column="prize"
                value={answers["prize"] || ""}
                onChange={handleAnswerChange}
            />
            {(answers.callDirection?.toLowerCase() === 'inbound' || answers.callDirection?.toLowerCase() === 'both') && (
                <CWQuestion
                    column="inboundUpsell"
                    value={answers["inboundUpsell"] || ""}
                    onChange={handleAnswerChange}
                    answers={answers}
                />
            )}
            {(answers.callDirection?.toLowerCase() === 'outbound' || answers.callDirection?.toLowerCase() === 'both') && (
                <CWQuestion
                    column="outboundUpsell"
                    value={answers["outboundUpsell"] || ""}
                    onChange={handleAnswerChange}
                    answers={answers}
                />
            )}
            <CWQuestion
                column="monitoring"
                value={answers["monitoring"] || ""}
                onChange={handleAnswerChange}
            />
        </>
    );
};

export default Step2;
