import React, { createContext, useContext } from 'react';

interface LayoutContextType {
    heading: string;
    tagline: string;
    setHeading?: React.Dispatch<React.SetStateAction<string>>;
    setTagline?: React.Dispatch<React.SetStateAction<string>>;
}

const LayoutContext = createContext<LayoutContextType>({
    heading: '',
    tagline: ''
});

export const useLayoutContext = () => useContext(LayoutContext);

export default LayoutContext;
