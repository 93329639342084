interface IconProps {
    value: boolean;  // true for checkmark, false for 'X'
    size?: number;   // Optional size in pixels
}

const YesNoIcon: React.FC<IconProps> = ({ value, size = 20 }) => {
    const successStyle = {
        fontSize: `${size}px`,
        color: '#28a745'  // Bootstrap's default green for "success"
    };

    const dangerStyle = {
        fontSize: `${size}px`,
        color: '#dc3545'  // Bootstrap's default red for "danger"
    };

    if (value) {
        return <span style={successStyle}>&#10004;</span>;
    } else {
        return <span style={dangerStyle}>&#10008;</span>;
    }
}

export default YesNoIcon;