import { z } from 'zod';
const phoneNumberPattern = /^\(\d{3}\) \d{3}-\d{4}$/;

const singlePhoneNumberSchema = z
  .string()
  .nonempty({ message: "Phone Number is required" })
  .refine((value) => phoneNumberPattern.test(value), {
    message: "Phone Number must be in (xxx) xxx-xxxx format",
  });

 export const validationSchema = z.object({
  firstName: z.string().nonempty({ message: "First Name is required" }),
  lastName: z.string().nonempty({ message: "Last Name is required" }),
  phoneNumbers: z.array(singlePhoneNumberSchema).refine((arr) => {
    // Using Set to remove duplicates and comparing lengths to determine if any duplicates were present
    return arr.length === new Set(arr).size;
  }, {
    message: "Duplicate phone numbers are not allowed",
  }),
  notes: z.string().nullable().optional(),
  complaintDate: z.union([z.date(), z.literal(null)]).refine(value => value !== null, {
    message: "Complaint Date cannot be null",
}),
  alledgedCallDate: z.union([z.date(), z.literal(null)]).refine(value => value !== null, {
      message: "Alledged Call Date cannot be null",
  }),
  fileComplaint: z.any()
});