import React, { useEffect } from 'react';
import { useWizard } from '../../../contexts/ComplianceGuide/ComplianceWizardContext';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import { useLayoutContext } from '../../../contexts/LayoutContext';

const Wizard: React.FC = () => {
  const { currentStep, setCurrentStep, stepComplete } = useWizard();
  const { heading, setHeading, tagline, setTagline } = useLayoutContext();

  useEffect(() => {
    if (setHeading && setTagline) {
        if (currentStep !== 3) {
            const newHeading = `Compliance Gap Analysis Wizard - Page ${currentStep} of 3`;
            setHeading(newHeading);
            setTagline('Please answer the following questions about your telemarketing activities. All questions must be answered to move on to the next step.');
        } else {
            setHeading('Results of your Compliance Gap Analysis');
            setTagline('Compliance Guide has identified the following areas where you need support with your compliance efforts.');
        }
    }
}, [currentStep, setHeading, setTagline]);

  return (
    <div>
      {currentStep === 1 && <Step1 />}
      {currentStep === 2 && <Step2 />}
      {currentStep === 3 && <Step3 />}

      <div>
        {currentStep > 1 && <button className="btn btn-secondary me-2" onClick={() => setCurrentStep(currentStep - 1)}>← Go Back</button>}
        {currentStep < 3 && (
          <button className="btn btn-secondary" disabled={!stepComplete[currentStep]} onClick={() => setCurrentStep(currentStep + 1)}>
            {currentStep === 1 ? 'Next Page' : 'Generate Compliance Checklist'}
          </button>
        )}
      </div>
    </div>
  );
};

export default Wizard;
