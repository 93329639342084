import React, { useState, ReactNode, ComponentType } from 'react';
import SiteLayout from '../layouts/SiteLayout';
import LayoutContext from '../contexts/LayoutContext';

type LayoutComponentType = ComponentType<{ children: ReactNode }>;

function withLayout(Component: ComponentType, Layout: LayoutComponentType = SiteLayout) {
    return () => {
        const [heading, setHeading] = useState('');
        const [tagline, setTagline] = useState('');

        return (
            <LayoutContext.Provider value={{ heading, tagline, setHeading, setTagline }}>
                <Layout>
                    <Component />
                </Layout>
            </LayoutContext.Provider>
        );
    };
}
export default withLayout;