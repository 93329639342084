import React, { useEffect } from 'react';
import data from './data.json';
import Section from './Section';
import { useLayoutContext } from '../../../contexts/LayoutContext';

const Index: React.FC = () => {
  
  const { setHeading, setTagline } = useLayoutContext();
  useEffect(() => {
    if (setHeading && setTagline) {
      setHeading('Other Tools');
      setTagline('Other compliance tools offered by Contact Center Compliance');
    }
  }, [setHeading, setTagline]);


  return (
    <>
      <div className="row w-100" style={{ marginTop: '1rem' }}>
        <div className="compliance-intro col">
          <div className="compliance-card-container">
            {data.map((sectionData, index) => (
              <Section key={index} {...sectionData} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Index;
