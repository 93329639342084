import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useLoginUserMutation } from '../services/authApi'
import { useAppDispatch } from '../app/hooks';
//import { setUser } from '../features/authSlice';
import { setAuthInfo } from '../features/authSlice';

const initialState = {
    acctId: '',
    username: '',
    password: ''
}

const Login = () => {
    const [formValue, setFormValue] = useState(initialState);
    const { acctId, username, password } = formValue;
    const navigate = useNavigate();
    const [loginUser, { data, isSuccess, isError }] = useLoginUserMutation();
    const dispatch = useAppDispatch();
    const handleChange = (e: any) => {
        setFormValue({
            ...formValue,
            [e.target.name]: e.target.value
        });
    };

    const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => { 
        e.preventDefault(); 
        if (acctId === '' || username === '' || password === '') {
            toast.error('Please fill all fields');
            return;
        } else {
            await loginUser({ acctId, username, password });
        }
    };

    useEffect(() => {
        if (isSuccess) {
            toast.success('Login successful');
            dispatch(setAuthInfo({ acctId: acctId, name: data.name, loginId: data.loginId, csrfToken: data.csrfToken }));
            //navigate('/upload-list');
            navigate('/compliance-guide/intro'); // Default to the CG Intro for now
        } else if (isError) {
            toast.error('Login failed');
        }
    }, [isSuccess, isError, acctId, data, dispatch, navigate]);

    return (
        <div className="form">
            <form className="login-form" onSubmit={handleLogin}>
                <img src="/images/DNCScrubLight.png" alt="Solution Logo" className="appLogo" />
                
                <input
                    type="text"
                    name="acctId"
                    value={acctId}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Account" />

                <input
                    type="text"
                    name="username"
                    value={username}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Username" />

                <input
                    type="password"
                    name="password"
                    value={password}
                    onChange={handleChange}
                    className="form-control"
                    placeholder="Password" />
                <button className="btn btn-primary" type="submit">Login</button>
                {/*<p className="message"><a href="#">Forgot your password?</a></p>*/}
            </form>
        </div>
    )
}

export default Login