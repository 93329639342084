import React from 'react';
import Card from './Card';
import { SectionData } from '../../../interfaces/ComplianceGuide/OtherTools/SectionData';
import GroupIcon from './GroupIcon';

const Section: React.FC<SectionData> = ({ group, cards }) => (
    <div className='pb-5'>
        <h3>
            <GroupIcon group={group} />
            {group}
        </h3>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {cards.map((card, index) => (
                <React.Fragment key={index}>
                    <Card {...card} />
                    {(index + 1) % 3 === 0 && <div style={{flexBasis: '100%', height: 0}}></div>}
                </React.Fragment>
            ))}
        </div>
    </div>
);


export default Section;
