import React, { useCallback, useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { HelmetProvider } from "react-helmet-async";

import withLayout from "./components/withLayout";
import LoginLayout from "./layouts/LoginLayout";
import ComplianceGuideLayout from "./layouts/ComplianceGuideLayout";

import ProjectSettings from "./pages/Main/ProjectSettings";
import CampaignSettings from "./pages/Main/CampaignSettings";
import InternalDNC from "./pages/Main/InternalDNC";
import EBRMaster from "./pages/Main/EBRMaster";
import DNCPolicy from "./pages/Main/DNCPolicy";
import SendPolicy from "./pages/Main/SendPolicy";
import ExemptionMaster from "./pages/Main/ExemptionMaster";
import PoliciesSent from "./pages/Reports/PoliciesSent";
import InvestigatePhoneNumber from "./pages/Reports/InvestigatePhoneNumber";
import Users from "./pages/Users/Users";
import AddUser from "./pages/Users/AddUser";
import Tcl from "./components/Tcl";

import CGIntro from "./pages/ComplianceGuide/Index";
import CGRegulatoryGuide from "./pages/ComplianceGuide/RegulatoryGuide/Index";
import CGCharts from "./pages/ComplianceGuide/Charts/Index";
import CGRegulatoryWatch from "./pages/ComplianceGuide/RegulatoryWatch/Index";
import CGDNCMap from "./pages/ComplianceGuide/DNCMap/Index";
import CGStatutes from "./pages/ComplianceGuide/Statutes/Index";
import CGFaqIndex from "./pages/ComplianceGuide/FAQ/Index";
import CGComplianceWizard from "./pages/ComplianceGuide/ComplianceWizard/Index";
import CGGlossary from "./pages/ComplianceGuide/Glossary/Index";
import CGLitigatorResearch from "./pages/ComplianceGuide/LitigatorResearch/Index";
import CGLitigatorScrub from "./pages/ComplianceGuide/LitigatorScrub/Index";
import CGSubmitLitigator from "./pages/ComplianceGuide/SubmitLitigator/Index";
import CGOtherTools from "./pages/ComplianceGuide/OtherTools/Index";
import CGFTCComplaintSearch from "./pages/ComplianceGuide/FTCComplaintSearch/Index";
import UploadList from "./pages/Main/UploadList/UploadList";
import useInactivity from "./hooks/useInactivity";
import {
  logout,
  setIsAuthenticated,
  setUnauthenticated,
} from "./features/authSlice";
import { useLogoutUserMutation } from "./services/authApi";
import { callApi, HttpMethod } from "./services/apiService";

import { useAuth } from "./contexts/AuthContext";
import { useAppDispatch } from "./app/hooks";
import BackToTop from "./components/BackToTop";
interface ExternalRedirectProps {
  to: string;
}

const ExternalRedirect: React.FC<ExternalRedirectProps> = ({ to }) => {
  useEffect(() => {
    window.location.href = to;
  }, [to]);

  return null;
};

function App() {
  const { isLoggedIn } = useAuth();

  let isDeveloper: boolean = false;
  if (process.env.REACT_APP_ENV === "development") {
    isDeveloper = true;
  }

  console.log(`isLoggedIn: ${isLoggedIn}`);
  const elapsedTime = 10 * 60 * 1000; //10mins
  const [showInactivityModal, setShowInactivityModal] = useState(false);
  const [modalTimerLeft, setModalTimeLeft] = useState(59); //59 secs
  const isInactive = useInactivity(elapsedTime);
  const handleModalTimerClose = () => setShowInactivityModal(false);
  const handleModalTimerShow = () => setShowInactivityModal(true);
  const [logoutUser] = useLogoutUserMutation();
  const dispatch = useAppDispatch();
  const tclLoginURL = `https://www.dncscrub.com/index.adp?afterLoginPage=${window.location.href}&action=sessionexpire`;

  const handleLogout = useCallback(() => {
    console.log("user logout");
    logoutUser({});
    dispatch(logout());
    handleModalTimerClose();
    if (window && window.top && window.top.location) {
      window.top.location.href = tclLoginURL;
    }
  }, [dispatch, logoutUser, tclLoginURL]);

  const handleLogIn = useCallback(async () => {
    console.log("user extend login expiration");
    const response = await callApi("users/isauthenticated", HttpMethod.GET);
    if (response.message === "Authorized") {
      dispatch(setIsAuthenticated());
    } else {
      dispatch(setUnauthenticated());
    }
    handleModalTimerClose();
  }, [dispatch]);

  useEffect(() => {
    if (
      isInactive &&
      !showInactivityModal &&
      !window.location.href.includes("/login") &&
      isLoggedIn
    ) {
      console.log("user is inactive");
      handleModalTimerShow();
    }
  }, [isInactive, isLoggedIn, showInactivityModal]);

  useEffect(() => {
    if (
      !isInactive &&
      !showInactivityModal &&
      !window.location.href.includes("/login") &&
      isLoggedIn
    ) {
      console.log("about to call extend login exipiry time");
      const timer = setInterval(async () => {
        await handleLogIn();
      }, elapsedTime);

      // Clear the timeout when the component is unmounted
      return () => clearInterval(timer);
    }
  }, [elapsedTime, handleLogIn, isInactive, isLoggedIn, showInactivityModal]);

  useEffect(() => {
    if (showInactivityModal && isLoggedIn) {
      console.log("show warning modal with timer 59secs");
      const timer = setInterval(() => {
        setModalTimeLeft((prevTime) => {
          if (prevTime <= 1) {
            try {
              handleLogout();
            } catch (err) {
              console.error("Error during logout:", err);
            }
            return 0;
          }
          return (prevTime -= 1);
        });
      }, 1000);

      return () => clearInterval(timer); // Cleanup on unmount
    }
  }, [handleLogout, isLoggedIn, showInactivityModal]);

  return (
    <>
      {showInactivityModal && (
        <div
          className="modal"
          tabIndex={-1}
          style={
            showInactivityModal ? { display: "block" } : { display: "none" }
          }
        >
          <div className="modal-dialog" onClick={(e) => e.stopPropagation()}>
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  {"Your session is about to expire!"}
                </h5>
              </div>
              <div className="modal-body">
                <p>
                  You will be logged out in <span>{modalTimerLeft}</span>{" "}
                  seconds. <br />
                  Do you want to stay signed in?
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={handleLogout}
                >
                  No, Sign me out
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleLogIn}
                >
                  Yes, Keep me signed in
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {showInactivityModal ? (
        <div className="modal-backdrop fade show"></div>
      ) : null}
      <div className="App">
        <HelmetProvider>
          <BrowserRouter>
            <ToastContainer />
            <Routes>
              <Route
                path="/"
                element={
                  isDeveloper ? (
                    //<Navigate to="/upload-list" replace />
                    <Navigate to="/compliance-guide/intro" replace />
                  ) : (
                    //<Navigate to="/login" replace />
                    <ExternalRedirect to="https://www.dncscrub.com/index.adp?afterLoginPage=https%3a%2f%2fapp.dncscrub.com%2fcompliance-guide%2fintro" />
                  )
                }
              />

              <Route path="/login" element={withLayout(Login, LoginLayout)()} />

              {/* Main */}
              <Route path="/upload-list" element={withLayout(UploadList)()} />
              <Route
                path="/project-settings"
                element={withLayout(ProjectSettings)()}
              />
              <Route
                path="/campaign-settings"
                element={withLayout(CampaignSettings)()}
              />
              <Route path="/internal-dnc" element={withLayout(InternalDNC)()} />
              <Route path="/ebr-master" element={withLayout(EBRMaster)()} />
              <Route path="/dnc-policy" element={withLayout(DNCPolicy)()} />
              <Route path="/send-policy" element={withLayout(SendPolicy)()} />
              <Route
                path="/exemption-master"
                element={withLayout(ExemptionMaster)()}
              />

              {/* Reports */}
              <Route
                path="/policies-sent"
                element={withLayout(PoliciesSent)()}
              />
              <Route
                path="/investigate-phone-number"
                element={withLayout(InvestigatePhoneNumber)()}
              />

              {/* Users */}
              <Route path="/users" element={withLayout(Users)()} />
              <Route path="/add-user" element={withLayout(AddUser)()} />

              <Route path="/dashboard" element={withLayout(Dashboard)()} />

              {/* Tcl app links */}
              <Route path="/tcl/:index" element={withLayout(Tcl)()} />

              {/* Compliance Guide */}
              <Route
                path="/compliance-guide/intro"
                element={withLayout(CGIntro, ComplianceGuideLayout)()}
              />
              <Route
                path="/compliance-guide/regulatory-guide"
                element={withLayout(CGRegulatoryGuide, ComplianceGuideLayout)()}
              />
              <Route
                path="/compliance-guide/regulatory-charts"
                element={withLayout(CGCharts, ComplianceGuideLayout)()}
              />
              <Route
                path="/compliance-guide/regulatory-watch"
                element={withLayout(CGRegulatoryWatch, ComplianceGuideLayout)()}
              />
              <Route
                path="/compliance-guide/dnc-map"
                element={withLayout(CGDNCMap, ComplianceGuideLayout)()}
              />
              <Route
                path="/compliance-guide/statutes"
                element={withLayout(CGStatutes, ComplianceGuideLayout)()}
              />
              <Route
                path="/compliance-guide/faq"
                element={withLayout(CGFaqIndex, ComplianceGuideLayout)()}
              />
              <Route
                path="/compliance-guide/compliance-wizard"
                element={withLayout(
                  CGComplianceWizard,
                  ComplianceGuideLayout
                )()}
              />
              {/* <Route
              path="/compliance-guide/exemption-master"
              element={withLayout(CGExemptionMaster, ComplianceGuideLayout)()}
            /> */}
              <Route
                path="/compliance-guide/glossary"
                element={withLayout(CGGlossary, ComplianceGuideLayout)()}
              />
              <Route
                path="/compliance-guide/litigator-research"
                element={withLayout(
                  CGLitigatorResearch,
                  ComplianceGuideLayout
                )()}
              />
              <Route
                path="/compliance-guide/litigator-scrub"
                element={withLayout(CGLitigatorScrub, ComplianceGuideLayout)()}
              />
              <Route
                path="/compliance-guide/ftc-complaint-search"
                element={withLayout(
                  CGFTCComplaintSearch,
                  ComplianceGuideLayout
                )()}
              />
              <Route
                path="/compliance-guide/submit-litigator"
                element={withLayout(CGSubmitLitigator, ComplianceGuideLayout)()}
              />
              <Route
                path="/compliance-guide/other-tools"
                element={withLayout(CGOtherTools, ComplianceGuideLayout)()}
              />
              <Route
                path="*"
                element={<ExternalRedirect to="https://www.dncscrub.com/" />}
              />
            </Routes>
          </BrowserRouter>
        </HelmetProvider>
        <BackToTop />
      </div>
    </>
  );
}

export default App;
