import React, { useEffect } from 'react';
import { ComplianceGuideProps } from '../../../../interfaces/ComplianceGuide/ComplianceGuideProps';
import RegulatoryGuideSectionHeader from '../../../../components/ComplianceGuide/RegulatoryGuideSectionHeader';

const TimeRestriction: React.FC<ComplianceGuideProps> = ({
  topic,
  region,
  data,
  isCheckedHideStatesWithNoRules,
}) => {
  useEffect(() => {
    // Find the div using its ID and change its display property
    const div = document.getElementById(`conditionalRender_${region}`);
    if (div) {
      if (!isCheckedHideStatesWithNoRules) {
        div.style.display = 'block'; // Or 'flex', 'grid', etc., depending on your layout requirements
      } else {
        // Check if data has a value
        if (
          data &&
          data.length > 0 &&
          (data[0] as any)?.complianceRule !== null
        ) {
          div.style.display = 'block';
        } else {
          div.style.display = 'none';
        }
      }
    }
  }, [data, isCheckedHideStatesWithNoRules, region]);

  if (!data || data.length === 0) {
    return (
      <>
        <RegulatoryGuideSectionHeader label={`${topic} (${region})`} />
        <div className='regulatory-contents'>
          There are no restrictions for {topic}.
        </div>
      </>
    );
  }
  const rec = JSON.parse(data)[0];

  return (
    <>
      <RegulatoryGuideSectionHeader label={`${topic} (${rec.state})`} />
      <div className='regulatory-contents'>
        {rec.callTimeWeekday === null &&
        rec.callTimeSaturday === null &&
        rec.callTimeSunday === null ? (
          <>
            {isCheckedHideStatesWithNoRules ? null : (
              <table>
                <tbody>
                  <tr>
                    <td colSpan={2}>
                      No state rule for calling time restrictions; Federal rules
                      apply (8:00 AM to 9:00 PM).
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
          </>
        ) : (
          <table className='table table-border content-width-table'>
            <tbody>
              <tr>
                <td>Weekday</td>
                <td>
                  {rec.callTimeWeekday ? rec.callTimeWeekday : 'Do Not Call'}
                </td>
              </tr>
              <tr>
                <td>Saturday</td>
                <td>
                  {rec.callTimeSaturday ? rec.callTimeSaturday : 'Do Not Call'}
                </td>
              </tr>
              <tr>
                <td>Sunday</td>
                <td>
                  {rec.callTimeSunday ? rec.callTimeSunday : 'Do Not Call'}
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </div>
    </>
  );
};

export default TimeRestriction;
