import React, { useState } from 'react';
import Sidebar from './Sidebar';
import Sessions from './Sessions';
import UploadFile from './UploadFile';

const UploadList = () => {
    const [campaignId, setCampaignId] = useState('');

    return (
        <table>
            <tr>
                <td width={350} valign='top'>
                <Sidebar sendData={setCampaignId} />
                </td>
                <td style={{'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'}}>
                <UploadFile dataFromSender={campaignId} /><br />
                <Sessions dataFromSender={campaignId} />
                </td>
            </tr>
        </table>
    )
}

export default UploadList