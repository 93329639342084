import IframeComponent from '../../../components/IframeComponent'
import React from 'react'

const Index = () => {
  return (
    <IframeComponent
      src="https://www.dncscrub.com/legal/statutes/index.adp?statutePopup=1"
      title="DNCScrub Statutes"
      style={{
        width: "100%",
        height: 'calc(100vh - 75px)',
        border: "0"
      }}
      allowFullScreen={false}
    />
  )
}

export default Index