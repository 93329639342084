import React from 'react';

import { Helmet } from 'react-helmet-async';
import CGNavBar from '../pages/ComplianceGuide/NavBar';
import LimitedVersionWarning from '../pages/ComplianceGuide/LimitedVersionWarning';

import { useAuth } from '../contexts/AuthContext';
import { useLayoutContext } from '../contexts/LayoutContext';

type ComplianceGuideLayoutProps = {
  children: React.ReactNode;
};



const ComplianceGuideLayout: React.FC<ComplianceGuideLayoutProps> = ({ children }) => {
  const { isLoggedIn } = useAuth();
  const { heading, tagline } = useLayoutContext();

  return (
    <>
      <Helmet>
        <title>DNCScrub - Compliance Guide</title>
        <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css" rel="stylesheet" />
        <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/js/bootstrap.bundle.min.js" />
        <link href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css" rel="stylesheet" />
      </Helmet>
      <div id="pagetop">

        <CGNavBar />

        <div className="container-fluid">
        <main className="col-12">
          <div id="sideNavMenu">
            <button aria-controls="sideNavMenu" className="btn btn-primary submenu-cta"
              data-bs-target="#sideNavMenu" data-bs-toggle="offcanvas" type=
              "button"><i className="bi bi-card-list subnav-menu"></i> <span className=
                "submenu-header">Compliance Guide Menu</span></button>
          </div>
          <div className="compliance-card-section">
            <div>
              <div className="col pagetitle">
                {heading}
              </div>

              <div className="row w-100">
                <div className="col">
                  <p>{tagline}</p>
                </div>
              </div>

              {children}

              {
                !isLoggedIn && 
                <div className="row mt-5">
                  <LimitedVersionWarning />
                </div>
              }

              {/* <div className="row position-fixed bottom-0 text-wrap col-12">
                <div className="disclaimer col-8">
                  <p><i className="bi bi-info-square subnav-icon d-none"></i>This site is provided
                    for informational purposes only. These materials do not constitute legal
                    advice and should not be used as a substitute for competent legal
                    counsel. No representations are made regarding the timeliness, accuracy,
                    or completeness of these materials.</p>
                </div>
              </div> */}
            </div>
          </div>
        </main>
        </div>
      </div>
    </>
  );
};

export default ComplianceGuideLayout;
