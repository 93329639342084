import { useSelector } from 'react-redux';
import { selectAuth } from '../features/authSlice';
import LoadingToRedirect from './LoadingToRedirect';

interface PrivateRouteProps {
  children: React.ReactNode;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const { isAuthenticated } = useSelector(selectAuth);

  if (isAuthenticated) {
    return <>{children}</>;
  } else {
    return <LoadingToRedirect />;
  }
}

export default PrivateRoute;
