import React, { useEffect } from 'react';
import { ComplianceGuideProps } from '../../../../interfaces/ComplianceGuide/ComplianceGuideProps';
import Citation from '../../../../components/ComplianceGuide/Citation';
import SafeHtml from '../../../../components/SafeHtml';
import RegulatoryGuideSectionHeader from '../../../../components/ComplianceGuide/RegulatoryGuideSectionHeader';
import RegulatoryGuideSection from '../../../../components/ComplianceGuide/RegulatoryGuideSection';

const DNCExemptions: React.FC<ComplianceGuideProps> = ({
  topic,
  region,
  data,
  isCheckedHideStatesWithNoRules,
}) => {
  useEffect(() => {
    // Find the div using its ID and change its display property
    const div = document.getElementById(`conditionalRender_${region}`);
    if (div) {
      if (!isCheckedHideStatesWithNoRules) {
        div.style.display = 'block'; // Or 'flex', 'grid', etc., depending on your layout requirements
      } else {
        // Check if data has a value
        if (
          data &&
          data.length > 0 &&
          (data[0] as any)?.complianceRule !== null
        ) {
          div.style.display = 'block';
        } else {
          div.style.display = 'none';
        }
      }
    }
  }, [data, isCheckedHideStatesWithNoRules, region]);

  if (!data || data.length === 0) {
    return (
      <>
        <RegulatoryGuideSectionHeader label={`${topic} (${region})`} />
        <div className='regulatory-contents'>
          There are no restrictions for {topic}.
        </div>
      </>
    );
  }
  const rec = JSON.parse(data)[0];
  const hideDNCExemption =
    rec.dncExistingCustomer === null &&
    rec.dncEbrTransaction === null &&
    rec.dncEbrInquiry === null &&
    rec.dncPermission === null;

  return (
    <>
      {hideDNCExemption ? (
        <RegulatoryGuideSectionHeader
          label={`${rec.state} does not have a "Do Not Call" law; default to federal law.`}
        />
      ) : (
        <>
          <RegulatoryGuideSectionHeader label={`${topic} (${rec.state})`} />
          <div className="regulatory-contents">
            <table className="table">
              <tr>
                <td>Existing Customer</td>
                <td>
                  <SafeHtml html={rec.dncExistingCustomer} />
                </td>
              </tr>
              <tr>
                <td>Transaction EBR (Former Customer)</td>
                <td>
                  <SafeHtml html={rec.dncEbrTransaction} />
                </td>
              </tr>
              <tr>
                <td>Inquiry EBR (Inquiring Consumer)</td>
                <td>
                  <SafeHtml html={rec.dncEbrInquiry} />
                </td>
              </tr>
              <tr>
                <td>Permission (Written or otherwise)</td>
                <td>
                  <SafeHtml html={rec.dncPermission} />
                </td>
              </tr>
              <tr>
                <td>Citations</td>
                <td>
                  <RegulatoryGuideSection
                    label=""
                    value={
                      <Citation
                        statuteId={rec.ebrDefStatuteId}
                        statuteName={rec.ebrDefStatuteNumber}
                        ref1={rec.ebrDefRef1}
                        ref2={rec.ebrDefRef2}
                        ref3={rec.ebrDefRef3}
                      />
                    }
                  />
                </td>
              </tr>
            </table>
            <RegulatoryGuideSection
              label="Definition of EBR"
              value={<SafeHtml html={rec.ebrDef} />}
            />
            <RegulatoryGuideSection
              label="Citation"
              value={
                <Citation
                  statuteId={rec.ebrDefStatuteId}
                  statuteName={rec.ebrDefStatuteNumber}
                  ref1={rec.ebrDefRef1}
                  ref2={rec.ebrDefRef2}
                  ref3={rec.ebrDefRef3}
                />
              }
            />
          </div>
        </>
      )}
    </>
  );
};

export default DNCExemptions;
