import React, { useEffect } from 'react';
import { ComplianceGuideProps } from '../../../../interfaces/ComplianceGuide/ComplianceGuideProps';
import Citation from '../../../../components/ComplianceGuide/Citation';
import SafeHtml from '../../../../components/SafeHtml';
import RegulatoryGuideSectionHeader from '../../../../components/ComplianceGuide/RegulatoryGuideSectionHeader';
import RegulatoryGuideSection from '../../../../components/ComplianceGuide/RegulatoryGuideSection';

const ContractCancellation: React.FC<ComplianceGuideProps> = ({
  topic,
  region,
  data,
  isCheckedHideStatesWithNoRules,
}) => {
  useEffect(() => {
    // Find the div using its ID and change its display property
    const div = document.getElementById(`conditionalRender_${region}`);
    if (div) {
      if (!isCheckedHideStatesWithNoRules) {
        div.style.display = 'block'; // Or 'flex', 'grid', etc., depending on your layout requirements
      } else {
        // Check if data has a value
        if (
          data &&
          data.length > 0 &&
          (data[0] as any)?.complianceRule !== null
        ) {
          div.style.display = 'block';
        } else {
          div.style.display = 'none';
        }
      }
    }
  }, [data, isCheckedHideStatesWithNoRules, region]);

  if (!data || data.length === 0) {
    return (
      <>
        {isCheckedHideStatesWithNoRules ? null : (
          <>
            <RegulatoryGuideSectionHeader label={`${topic} (${region})`} />
            <div className='regulatory-contents'>
              <RegulatoryGuideSection label='' value='N/A' />
            </div>
          </>
        )}
      </>
    );
  }
  const rec = JSON.parse(data)[0];

  return (
    <>
      {isCheckedHideStatesWithNoRules && rec.complianceRule === null ? null : (
        <RegulatoryGuideSectionHeader label={`${topic} (${rec.state})`} />
      )}
      <div className='regulatory-contents'>
        {rec.state !== 'FCC' && rec.state !== 'FTC' ? (
          <>
            {rec.complianceRule && (
              <>
                <RegulatoryGuideSection
                  label='Compliance Rule'
                  value={<SafeHtml html={rec.complianceRule} />}
                />
                <RegulatoryGuideSection
                  label='Citation'
                  value={
                    <Citation
                      statuteId={rec.complianceRuleStatuteId}
                      statuteName={rec.complianceRuleStatuteNumber}
                      ref1={rec.complianceRuleRef1}
                      ref2={rec.complianceRuleRef2}
                      ref3={rec.complianceRuleRef3}
                    />
                  }
                />
              </>
            )}
            <RegulatoryGuideSection
              label='Actual Contract Language'
              value={<SafeHtml html={rec.language} />}
            />
            {rec.inboundRule && (
              <RegulatoryGuideSection
                label='Inbound Rule'
                value={<SafeHtml html={rec.inboundRule} />}
              />
            )}
          </>
        ) : isCheckedHideStatesWithNoRules === true ? null : (
          <>N/A</>
        )}
      </div>
      {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
    </>
  );
};

export default ContractCancellation;
