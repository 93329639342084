import React from 'react';
import TreeView from '../../../components/Main/TreeView';
import { DataSenderProps } from '../../../interfaces/DataSenderProps';

interface SidebarProps extends DataSenderProps<string> {}

const Sidebar: React.FC<SidebarProps> = ({ sendData }) => {
    const handleCampaignClick = (campaign: any) => {
        sendData(campaign.id);
    };


    return (
        <div>
            <TreeView onCampaignClick={handleCampaignClick} />
        </div>

            
    );
};

export default Sidebar;
