import React from 'react';

interface RegulatoryGuideSectionProps {
    label: string;
    value: React.ReactNode;
    hideIfValue?: string | number | null;
}

const RegulatoryGuideSection: React.FC<RegulatoryGuideSectionProps> = ({ label, value, hideIfValue }) => {
    const isCitation = label.toLowerCase().startsWith("citation");
    const containerClass = isCitation ? "d-flex align-items-center" : "label-value-container pt-1";
    const labelClass = isCitation ? "label" : "label pb-2";
    const valueClass = isCitation ? "ms-3" : (label ? "value ps-3" : "value");

    if (typeof hideIfValue !== 'undefined' && !hideIfValue) {
        return null;
    }

    return (
        <div className={containerClass}>
            {label && <div className={labelClass}><b>{label}:</b></div>}
            <div className={valueClass}>{value}</div>
        </div>
    );
}

export default RegulatoryGuideSection;
