import React, { useEffect } from 'react';
import { ComplianceGuideProps } from '../../../../interfaces/ComplianceGuide/ComplianceGuideProps';
import Citation from '../../../../components/ComplianceGuide/Citation';
import SafeHtml from '../../../../components/SafeHtml';
import RegulatoryGuideSectionHeader from '../../../../components/ComplianceGuide/RegulatoryGuideSectionHeader';
import RegulatoryGuideSection from '../../../../components/ComplianceGuide/RegulatoryGuideSection';

const PrerecCalls: React.FC<ComplianceGuideProps> = ({
  topic,
  region,
  data,
  isCheckedHideStatesWithNoRules,
}) => {
  useEffect(() => {
    // Find the div using its ID and change its display property
    const div = document.getElementById(`conditionalRender_${region}`);
    if (div) {
      if (!isCheckedHideStatesWithNoRules) {
        div.style.display = 'block'; // Or 'flex', 'grid', etc., depending on your layout requirements
      } else {
        // Check if data has a value
        if (
          data &&
          data.length > 0 &&
          (data[0] as any)?.complianceRule !== null
        ) {
          div.style.display = 'block';
        } else {
          div.style.display = 'none';
        }
      }
    }
  }, [data, isCheckedHideStatesWithNoRules, region]);

  if (!data || data.length === 0) {
    return (
      <>
        <RegulatoryGuideSectionHeader label={`${topic} (${region})`} />
        <div className='regulatory-contents'>
          There are no restrictions for {topic}.
        </div>
      </>
    );
  }
  const rec = JSON.parse(data)[0];

  return (
    <>
      <RegulatoryGuideSectionHeader label={`${topic} (${rec.state})`} />
      <div className='regulatory-contents'>
        <RegulatoryGuideSection
          label='State-specific Restrictions'
          value={<SafeHtml html={rec.adadClassification} />}
          hideIfValue={rec.adadClassification}
        />

        <RegulatoryGuideSection
          label='Express consent exemption in place?  Impact of additional rules'
          value={<SafeHtml html={rec.adadNotes} />}
          hideIfValue={rec.adadNotes}
        />
        <RegulatoryGuideSection
          label='Compliance Rule'
          value={<SafeHtml html={rec.complianceRule} />}
          hideIfValue={rec.complianceRule}
        />
        <RegulatoryGuideSection
          label='Citation'
          value={
            <Citation
              statuteId={rec.complianceRuleStatuteId}
              statuteName={rec.complianceRuleStatuteNumber}
              ref1={rec.complianceRuleRef1}
              ref2={rec.complianceRuleRef2}
              ref3={rec.complianceRuleRef3}
            />
          }
          hideIfValue={rec.complianceRule}
        />
        {rec.inboundRule ? (
          <RegulatoryGuideSection
            label='Inbound Rule'
            value={<SafeHtml html={rec.inboundRule} />}
            hideIfValue={rec.inboundRule}
          />
        ) : null}
      </div>
    </>
  );
};

export default PrerecCalls;
