import React from 'react'
const styles = {
    divRegister: {
        backgroundColor: 'lightskyblue',
        margin: 10,
        padding: 10,
        borderRadius: 5,
        bordercolor: 'black',
        borderStyle: 'solid',
        borderWidth: 1,
        fontSize: '12px'
    },
    listItem: {
        marginBottom: '15px'
    },
    link: {
        textDecoration: 'none',
        color: '#333',
        fontSize: '16px'
    }
};
const LimitedVersionWarning = () => {
    return (
        <div className='abclimited'  style={styles.divRegister}>
            This limited version of the Compliance Guide is free for demonstration purposes.
            Purchasing the full version will unlock access to:
            <ul>
                <li>Your ExemptionMaster analysis results</li>
                <li>Regulatory Info for all 50 states</li>
                <li>Improved navigation for full text of legal statutes</li>
            </ul>
        </div>
    )
}

export default LimitedVersionWarning