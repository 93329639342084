import React, { useEffect, useState, useCallback, useRef } from "react";
import { callApi, HttpMethod } from "../../../services/apiService";
import { DataReceiverProps } from '../../../interfaces/DataReceiverProps';
import SessionCard from "./SessionCard";
import { Session } from "../../../interfaces/Session";

interface SessionsProps extends DataReceiverProps<string> { }

const Sessions: React.FC<SessionsProps> = ({ dataFromSender }) => {
    const campaignId = dataFromSender;
    let prevCampaignId = useRef("");
    const [sessions, setSessions] = useState<Session[]>([]);
    const [lastFetched, setLastFetched] = useState<string | null>(null);
    const lastFetchedRef = useRef(lastFetched);

    const refreshInterval = 50 * 1000; // 30 seconds

    const fetchSessions = useCallback(async () => {
        try {
            if (campaignId === '') return;
            let lastFetchedUriDate = "";
            //let lastFetchedDate = lastFetchedRef.current ? new Date(lastFetchedRef.current) : new Date();
            if (lastFetchedRef.current === null) {
                lastFetchedUriDate = "";
            } else {
                let lastFetchedDate = lastFetchedRef.current ? new Date(lastFetchedRef.current) : new Date();
                lastFetchedDate.setSeconds(lastFetchedDate.getSeconds() - refreshInterval / 1000);
                lastFetchedUriDate = lastFetchedDate.toISOString();
            }

            if(prevCampaignId.current  !== campaignId) {
                lastFetchedUriDate = "";
                prevCampaignId.current = campaignId;
            } 
            let apiUrl = `Sessions/GetSessionRows?campaignId=${campaignId}&dateFrom=${lastFetchedUriDate}&dateTo=`;

            const fetchedSessions: Session[] = await callApi(apiUrl, HttpMethod.GET);

            setSessions(prevSessions => {
                const updatedSessions = [...prevSessions];
                fetchedSessions.forEach(fetchedSession => {
                    const index = updatedSessions.findIndex(s => s.sessionId === fetchedSession.sessionId);
                    if (index !== -1) {
                        updatedSessions[index] = fetchedSession;
                    } else {
                        updatedSessions.push(fetchedSession);
                    }
                });
                return updatedSessions;
            });
        } catch (error) {
            console.error('Error fetching sessions:', error);
        }
    }, [campaignId, refreshInterval]);

    useEffect(() => {
        lastFetchedRef.current = lastFetched;
    }, [lastFetched]);

    useEffect(() => {
        setSessions([]);
        setLastFetched(null);
    }, [dataFromSender]);

    useEffect(() => {
        

        fetchSessions();

        const intervalId = setInterval(() => {
            fetchSessions();
            setLastFetched(new Date().toISOString());
        }, refreshInterval);

        return () => clearInterval(intervalId);
    }, [fetchSessions, refreshInterval]);

    return (
        <div>
            Selected Campaign: {dataFromSender}<br />
            {sessions.map(session => (
                <SessionCard key={session.sessionId} session={session} />
            ))}
        </div>
    );
};

export default Sessions;
