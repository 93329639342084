import React, { useState, useEffect, useRef } from 'react';
import { useWizard } from '../../../contexts/ComplianceGuide/ComplianceWizardContext';
import { callApi, HttpMethod } from '../../../services/apiService';
import Loading from '../../../components/Loading';
import styles from './Index.module.css';

interface NestedAnswer {
    [key: string]: string | { [key: string]: any };
}

const getValueFromAnswer = (answers: NestedAnswer, path: string): any => {
    const keys = path.split('.');
    let value: any = answers;

    for (const key of keys) {
        if (value[key] !== undefined) {
            value = value[key];
        } else {
            return undefined;
        }
    }
    return value;
};

const shouldSkipRecord = (
    recordValue: string | null,
    answerValue: string | null,
    expectedRecordValue: string,
    expectedAnswerValue: string
) => {
    return (
        recordValue && answerValue &&
        recordValue.trim().toLowerCase() === expectedRecordValue.trim().toLowerCase() &&
        answerValue.trim().toLowerCase() === expectedAnswerValue.trim().toLowerCase()
    );
};

const Step3: React.FC = () => {
    const { answers } = useWizard();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [checklist, setChecklist] = useState<any[]>([]);

    const lastCategory = useRef<string>('');
    const lastSubCategory = useRef<string>('');
    const lastRecommendation = useRef<string>('');

    const callDirection = getValueFromAnswer(answers, 'callDirection');
    const size = getValueFromAnswer(answers, 'size');
    const inquiryEBR = getValueFromAnswer(answers, 'inquiryEBR');
    const transactionEBR = getValueFromAnswer(answers, 'transactionEBR');
    const permissionEBR = getValueFromAnswer(answers, 'permissionEBR');
    const prize = getValueFromAnswer(answers, 'prize');
    const monitoring = getValueFromAnswer(answers, 'monitoring');
    const predictiveDialer = getValueFromAnswer(answers, 'predictiveDialer');
    const prerecorded = getValueFromAnswer(answers, 'prerecorded');
    const inboundUpsell = getValueFromAnswer(answers, 'inboundUpsell');
    const inboundUpsellType = getValueFromAnswer(answers, 'inboundUpsellType');
    const outboundUpsell = getValueFromAnswer(answers, 'outboundUpsell');
    const outboundUpsellType = getValueFromAnswer(answers, 'outboundUpsellType');
    

    useEffect(() => {
        async function ShowChecklist() {
            try {
                let apiUrl = `ComplianceGuide/ComplianceWizardChecklist`;
                const fetchedData = await callApi(apiUrl, HttpMethod.GET);
                setChecklist(fetchedData);
                setIsLoading(false);
            } catch (err) {
                const errorMessage = (err as Error).message;
                console.log(errorMessage);
                setIsLoading(false); // Set loading to false in case of error to not leave the user in perpetual loading state
            }
        }

        ShowChecklist();
    }, []);


    return (
        <>
            <div className="d-none">
                <h2>Your Answers:</h2>
                callDirection: {callDirection}<br />
                size: {size}<br />
                inquiryEBR: {inquiryEBR}<br />
                transactionEBR: {transactionEBR}<br />
                permissionEBR: {permissionEBR}<br />
                prize: {prize}<br />
                monitoring: {monitoring}<br />
                predictiveDialer: {predictiveDialer}<br />
                prerecorded: {prerecorded}<br />
                inboundUpsell: {inboundUpsell}<br />
                inboundUpsellType: {inboundUpsellType}<br />
                outboundUpsell: {outboundUpsell}<br />
                outboundUpsellType: {outboundUpsellType}<br />
                <br />
            </div>

            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <table className="table table-bordered">
                        <tbody>
                            {checklist.map((record, index) => {
                                const isDifferentCategory = record.category !== lastCategory.current;
                                const isDifferentSubCategory = record.subCategory !== lastSubCategory.current;
                                const isDifferentRecommendation = record.recommendation !== lastRecommendation.current;

                                if (record.callDirection === 'Outbound' && callDirection === 'Inbound') {
                                    return null;
                                }
                                if (shouldSkipRecord(record.callDirection, callDirection, 'Outbound', 'Inbound')) {
                                    return null;
                                }
                                if (shouldSkipRecord(record.prize, prize, 'Yes', 'No')) {
                                    return null;
                                }
                                if (shouldSkipRecord(record.monitoring, monitoring, 'Yes', 'No')) {
                                    return null;
                                }
                                if (shouldSkipRecord(record.inquiryEbr, inquiryEBR, 'Yes', 'No')) {
                                    return null;
                                }
                                if (shouldSkipRecord(record.transactionEbr, transactionEBR, 'Yes', 'No')) {
                                    return null;
                                }
                                if (shouldSkipRecord(record.permissionEbr, permissionEBR, 'Yes', 'No')) {
                                    return null;
                                }
                                if (shouldSkipRecord(record.predictiveDialer, predictiveDialer, 'Yes', 'No')) {
                                    return null;
                                }
                                if (shouldSkipRecord(record.prerecorded, prerecorded, 'Yes', 'No')) {
                                    return null;
                                }
                                if (shouldSkipRecord(record.inboundUpsell, inboundUpsell, 'Yes', 'No')) {
                                    return null;
                                }
                                if (shouldSkipRecord(record.inboundUpsellType, inboundUpsellType, 'External', 'Internal')) {
                                    return null;
                                }
                                if (shouldSkipRecord(record.outboundUpsell, outboundUpsell, 'Yes', 'No')) {
                                    return null;
                                }
                                if (shouldSkipRecord(record.outboundUpsellType, outboundUpsellType, 'External', 'Internal')) {
                                    return null;
                                }

                                lastCategory.current = record.category;
                                lastSubCategory.current = record.subCategory;
                                lastRecommendation.current = record.recommendation;

                                return (
                                    <React.Fragment key={index}>
                                        {isDifferentCategory && (
                                            <>
                                                <tr className="border-0">
                                                    <td className="border-0">
                                                        &nbsp;
                                                    </td>
                                                </tr>
                                                <tr className="border-0">
                                                    <td className="border-0">
                                                        <h4 className={styles.fixHeaderColor}>{record.category}</h4>
                                                    </td>
                                                </tr>
                                            </>
                                        )}
                                        {isDifferentSubCategory && (
                                            <>
                                                {!isDifferentCategory && (
                                                    <tr className="border-0 py-0">
                                                        <td className="border-0 py-0">
                                                            &nbsp;
                                                        </td>
                                                    </tr>
                                                )}
                                                <tr>
                                                    <td className={styles.cwRecommendations}>
                                                        <span >
                                                            {record.subCategory} Recommendations
                                                        </span>
                                                    </td>
                                                </tr>
                                            </>

                                        )}
                                        {isDifferentRecommendation && (
                                            <tr className={styles.hideBottomBorder}>
                                                <td>
                                                    {record.recommendation}
                                                    <br />&nbsp;<br />

                                                </td>
                                            </tr>
                                        )}

                                        <tr className={styles.hideTopBorder}>
                                            <td>
                                                {isDifferentRecommendation && (
                                                    <>
                                                        <i>Checklist Item(s):</i>
                                                        <br />
                                                    </>

                                                )}
                                                {record.item}
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                );
                            })}
                        </tbody>
                    </table>
                </>
            )}
        </>
    );
};

export default Step3;
