import React from 'react'
import NavBar from './NavBar';

function Header() {
    const dropdowns = [
        {
            label: "Main",
            href: "/",
            items: [
                { label: "Upload List", href: "/upload-list", isActive: true },
                { label: "Project Settings", href: "/project-settings" },
                { label: "Campaign Settings", href: "/campaign-settings" },
                { label: "Internal DNC", href: "/internal-dnc" },
                { label: "EBR Master", href: "/ebr-master" },
                { label: "DNC Policy", href: "/dnc-policy" },
                { label: "Send Policy", href: "/send-policy" },
                { label: "Exemption Master", href: "/exemption-master" },
            ],
        },
        {
            label: "Reports",
            href: "/policies-sent",
            items: [
                { label: "Policies Sent", href: "/policies-sent", isActive: true },
                { label: "Investigate Phone Number", href: "/investigate-phone-number" },
            ],
        },
        {
            label: "Users",
            href: "/users",
            items: [
                { label: "Add User", href: "/users", isActive: true },
                { label: "User Admin", href: "/add-user" },
            ],
        },
        {
            label: "Help",
            href: "/help",
            items: [],
        },
        {
            label: "Compliance Guide",
            href: "/",
            items: [
                { label: "Intro", href: "/compliance-guide/intro", isActive: true },
                { label: "Regulatory Guide", href: "/compliance-guide/regulatory-guide" },
                { label: "Regulatory Watch", href: "/compliance-guide/regulatory-watch" },
                { label: "FAQ", href: "/compliance-guide/faq" },
                { label: "DNC Map", href: "/compliance-guide/dnc-map" },
                { label: "Statutes", href: "/compliance-guide/statutes" },
                { label: "Compliance Wizard", href: "/compliance-guide/compliance-wizard" },
                { label: "Exemption Master", href: "/compliance-guide/exemption-master" },
                { label: "Glossary", href: "/compliance-guide/glossary" },
            ],
        },
        {
            label: "TrustCall Monitor",
            href: "/tcl/4",
            items: [],
        },
        {
            label: "Litigator Research",
            href: "/tcl/5",
            items: [],
        },
        {
            label: "TrustCall Lookup",
            href: "/tcl/6",
            items: [],
        },
        {
            label: "Sysadmin",
            href: "/",
            items: [
                { label: "acct", href: "/" },
                { label: "Accounts", href: "/" },
                { label: "Billing", href: "/" },
                { label: "Settings", href: "/" },
            ],
        },
        {
            label: "Sales Report",
            href: "/tcl/7",
            items: [],
        },
    ];

    const user = {
        name: "Abhi K",
        role: "CCC Support",
        username: "jeff.sysadmin",
        iconSrc: "https://www.dncscrub.com/images/blue/user-admin.gif"
    };

    return (
        <header>
            <NavBar dropdowns={dropdowns} user={user} />
        </header>
    )
}

export default Header