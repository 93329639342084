import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import Cookies from 'js-cookie';

export interface AuthState {
    acctId: string | null;
    name: string | null;
    loginId: string | null;
    csrfToken: string | null;
    isAuthenticated: boolean | null; 
    isLoading: boolean;
}

// Initialize state from sessionStorage or defaults
const storedUser = JSON.parse(sessionStorage.getItem('user') || '{}');

const initialState: AuthState = {
    acctId: storedUser.acctId || null,
    name: storedUser.name || null,
    loginId: storedUser.loginId || null,
    csrfToken: storedUser.csrfToken || null,
    isAuthenticated: !!storedUser.acctId,
    isLoading: false
}

let domainName: string = ".dncscrub.com";

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuthInfo: (state, action: PayloadAction<{ acctId: string, name: string, loginId: string, csrfToken: string }>) => {
            sessionStorage.setItem('user',
                JSON.stringify({
                    name: action.payload.name,
                    acctId: action.payload.acctId,
                    loginId: action.payload.loginId,
                    csrfToken: action.payload.csrfToken
                })
            );
            state.acctId = action.payload.acctId;
            state.name = action.payload.name;
            state.loginId = action.payload.loginId;
            state.csrfToken = action.payload.csrfToken;
            state.isAuthenticated = true;
            state.isLoading = false;

            Cookies.set('acctId', action.payload.acctId, { domain: domainName, path: "/" }); 
            Cookies.set('name', action.payload.name, { domain: domainName, path: "/" });  
            Cookies.set('loginId', action.payload.loginId, { domain: domainName, path: "/" });
            Cookies.set('csrfToken', action.payload.csrfToken, { domain: domainName, path: "/" });
        },
        logout: (state) => {
            sessionStorage.clear();
            state.acctId = null;
            state.name = null;
            state.loginId = null;
            state.csrfToken = null;
            state.isAuthenticated = false;

            // Remove the token and user data from cookies
            Cookies.remove('acctId', { domain: domainName, path: "/" });
            Cookies.remove('name', { domain: domainName, path: "/" });
            Cookies.remove('loginId', { domain: domainName, path: "/" });
            Cookies.remove('csrfToken', { domain: domainName, path: "/" });
        },
        startLoading: (state) => {
            state.isLoading = true;
        },
        setUnauthenticated: (state) => {
            state.isAuthenticated = false;
            state.isLoading = false;
        },
        setIsAuthenticated: (state) => {
            state.isAuthenticated = true;
        },
    }
});

export const selectAuth = (state: RootState) => state.auth;

export const { setAuthInfo, logout, startLoading, setUnauthenticated, setIsAuthenticated } = authSlice.actions;

export default authSlice.reducer;
