import React, { createContext, useContext, useState, useEffect, ReactNode, FunctionComponent } from 'react';
import { callApi, HttpMethod } from '../services/apiService'; 

interface AuthContextProps {
  isLoggedIn: boolean | null;
  setIsLoggedIn: (value: boolean | null) => void;
  error: string | null;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: FunctionComponent<AuthProviderProps> = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const response = await callApi("users/isauthenticated", HttpMethod.GET);

        if (response.message === "Authorized") {
          setIsLoggedIn(true);
        } else {
          setIsLoggedIn(false);
        }
      } catch (err) {
        const errorMessage = (err as Error).message;

        if (errorMessage === "Unauthorized") {
          setIsLoggedIn(false);
        } else {
          setError(errorMessage);
        }
      }
    };

    checkAuthentication();
  }, []);

  return (
    <AuthContext.Provider value={{ isLoggedIn, setIsLoggedIn, error }}>
      {children}
    </AuthContext.Provider>
  );
};
