import React from 'react';
import { CardData } from '../../../interfaces/ComplianceGuide/OtherTools/CardData';

const Card: React.FC<CardData> = ({ title, description, externalLink }) => (
  <>
    <div className="col-sm-4 flex-container pb-5">
      <div className="compliance-card h-100">
        <div className="card-block">
          <h4 className="compliance-card-title"><a href={`${externalLink}`} target="_blank" rel="noreferrer"><span>{title}</span></a>
          </h4>
          <hr />
          <p className="card-text">{description}</p>
        </div>
      </div>
    </div>
  </>

);

export default Card;
