import IframeComponent from '../../../components/IframeComponent'
import React from 'react'

const Index = () => {
  return (
    <IframeComponent
      src="https://core.dncscrub.com/app/litigator/search/index"
      title="Litigator Research"
      style={{
        marginLeft: "-45px",
        paddingTop: "-175px",
        marginTop: "-25px",
        width: "100%",
        height: "85vh",
        border: "0"
      }}
      allowFullScreen={true}
    />
  )
}

export default Index