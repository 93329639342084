import React, { useCallback } from "react";
import parse from "html-react-parser";
import SafeHtml from "./SafeHtml";
import { ModalLink } from "./ComplianceGuide/ModalLink";

type ColumnInfo = {
  label: string;
  field: string;
};

type Props = {
  columns: ColumnInfo[];
  data: any;
  hideTableHeader: boolean;
  showNPOHeader: boolean;
  topic: string;
};

export const Table = React.memo(
  ({ columns, data, hideTableHeader, showNPOHeader, topic }: Props) => {
    const displayContent = useCallback(
      (fieldName: string, fieldValue: string, fieldRow: any): any => {
        if (
          fieldValue?.toLowerCase() === "true" ||
          fieldValue?.toLowerCase() === "yes" ||
          fieldValue === "1" ||
          fieldValue === "2" ||
          fieldValue?.toLowerCase() === "dma"
        ) {
          return <img src="/images/check-org.gif" alt="check logo" />;
        }
        if (
          fieldValue?.toLowerCase() === "false" ||
          fieldValue?.toLowerCase() === "no" ||
          fieldValue === "0"
        ) {
          return "";
        }
        if (topic === "LegalCommRegSummaries" && fieldName === "citation") {
          const definitionsLabel = fieldRow["definitionsLabel"] ?? "";
          const ruleLabel = fieldRow["ruleLabel"] ?? "";
          return (
            <>
              {fieldRow["definitionsURL"] && (
                <ModalLink
                  label={definitionsLabel}
                  url={fieldRow["definitionsURL"]}
                  labelName={definitionsLabel ? "Definitions : " : ""}
                />
              )}
              {fieldRow["ruleURL"] && (
                <>
                  <br />
                  <ModalLink
                    label={ruleLabel}
                    url={fieldRow["ruleURL"]}
                    labelName={ruleLabel ? "Rule : " : ""}
                  />
                </>
              )}
            </>
          );
        }
        if (topic === "LegalDncSummaries" && fieldName === "citations") {
          const ruleLabel = fieldRow["ruleLabel"] ?? "";
          const implementationLabel = fieldRow["implementationLabel"] ?? "";
          const definitionsLabel = fieldRow["definitionsLabel"] ?? "";
          return (
            <>
              {fieldRow["ruleURL"] && (
                <ModalLink
                  label={ruleLabel}
                  url={fieldRow["ruleURL"]}
                  labelName={ruleLabel ? "Rule : " : ""}
                />
              )}
              {fieldRow["implementationURL"] && (
                <>
                  <br />
                  <ModalLink
                    label={implementationLabel}
                    url={fieldRow["implementationURL"]}
                    labelName={implementationLabel ? "Implementation : " : ""}
                  />
                </>
              )}
              {fieldRow["definitionsURL"] && (
                <>
                  <br />
                  <ModalLink
                    label={definitionsLabel}
                    url={fieldRow["definitionsURL"]}
                    labelName={definitionsLabel ? "Definitions : " : ""}
                  />
                </>
              )}
            </>
          );
        }
        if (
          topic === "LegalNpoExemptionSummaries" &&
          fieldName === "citation"
        ) {
          return (
            <ModalLink label={fieldRow["label"]} url={fieldRow["citation"]} />
          );
        }
        if (topic === "LegalDncEbrPermSummaries" && fieldName === "citations") {
          return (
            <ModalLink label={fieldRow["label"]} url={fieldRow["citations"]} />
          );
        }
        if (
          topic === "LegalCallMonitoringSummaries" &&
          fieldName === "citations"
        ) {
          const definitionsLabel = fieldRow["definitionsLabel"] ?? "";
          const ruleLabel = fieldRow["ruleLabel"] ?? "";
          return (
            <>
              {fieldRow["ruleURL"] ? (
                <ModalLink
                  label={ruleLabel}
                  url={fieldRow["ruleURL"]}
                  labelName={ruleLabel ? "Rule : " : ""}
                />
              ) : (
                <>
                  <span style={{ color: "black" }}>{ruleLabel}</span>
                </>
              )}
              {fieldRow["definitionsURL"] ? (
                <>
                  <br />
                  <ModalLink
                    label={definitionsLabel}
                    url={fieldRow["definitionsURL"]}
                    labelName={definitionsLabel ? "Definitions : " : ""}
                  />
                </>
              ) : (
                <>
                  <br />
                  <span style={{ color: "black" }}>{definitionsLabel}</span>
                </>
              )}
            </>
          );
        }
        if (topic === "LegalAdadSummaries" && fieldName === "citations") {
          const definitionsLabel = fieldRow["definitionsLabel"] ?? "";
          const ruleLabel = fieldRow["ruleLabel"] ?? "";
          return (
            <>
              {fieldRow["ruleURL"] && (
                <ModalLink
                  label={ruleLabel}
                  url={fieldRow["ruleURL"]}
                  labelName={ruleLabel ? "Rule : " : ""}
                />
              )}
              {fieldRow["definitionsURL"] && (
                <>
                  <br />
                  <ModalLink
                    label={definitionsLabel}
                    url={fieldRow["definitionsURL"]}
                    labelName={definitionsLabel ? "Definitions : " : ""}
                  />
                </>
              )}
            </>
          );
        }
        if (topic === "LegalCitationSummaries") {
          if (fieldName === "registration") {
            return (
              <ModalLink
                label={fieldRow["registrationLabel"]}
                url={fieldRow["registration"]}
              />
            );
          }
          if (fieldName === "doNotCall") {
            return (
              <ModalLink
                label={fieldRow["doNotCallLabel"]}
                url={fieldRow["doNotCall"]}
              />
            );
          }
          if (fieldName === "idDisclosure") {
            return (
              <ModalLink
                label={fieldRow["idDisclosureLabel"]}
                url={fieldRow["idDisclosure"]}
              />
            );
          }
          if (fieldName === "contractCancellation") {
            return (
              <ModalLink
                label={fieldRow["contractCancellationLabel"]}
                url={fieldRow["contractCancellation"]}
              />
            );
          }
          if (fieldName === "permissionToContinue") {
            return (
              <ModalLink
                label={fieldRow["permissionToContinueLabel"]}
                url={fieldRow["permissionToContinue"]}
              />
            );
          }
          if (fieldName === "noRebuttal") {
            return (
              <ModalLink
                label={fieldRow["noRebuttalLabel"]}
                url={fieldRow["noRebuttal"]}
              />
            );
          }
        }
        return parse(fieldValue ?? "");
      },
      [topic]
    );
    const handleHeaderWidth = useCallback(
      (label: string): string | number | undefined => {
        if (label?.toLowerCase() === "jurisdiction") {
          return 160;
        }
        if (label?.toLocaleLowerCase() === "citation") {
          return 250;
        }
        return "auto";
      },
      []
    );
    const tableDisplay =
      data.length > 0 ? (
        <table className="table table-bordered table-hover table-striped border-primary">
          {!hideTableHeader && (
            <thead>
              {showNPOHeader && (
                <tr>
                  <th>Jurisdiction</th>
                  <th colSpan={2}>Calls with solicitation for donation</th>
                  <th colSpan={2}>
                    Calls with solicitation for a sale of good/service
                  </th>
                  <th>Citation</th>
                </tr>
              )}
              <tr>
                {columns.map((col, index) => (
                  <th
                    key={index}
                    style={{ width: handleHeaderWidth(col.label) }}
                  >
                    {col.label}
                  </th>
                ))}
              </tr>
            </thead>
          )}
          <tbody>
            {data.map((row: any, index: any) => (
              <tr key={index}>
                {columns.map((col, colIndex) => (
                  <td key={colIndex}>
                    {displayContent(col.field, row[col.field], row)}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <h5>{"No records found."}</h5>
      );
    return tableDisplay;
  }
);
