import React from 'react'
import { WizardProvider } from '../../../contexts/ComplianceGuide/ComplianceWizardContext';
import Wizard from './Wizard';
import styles from './Index.module.css';

const Index = () => {
  return (
    <WizardProvider>
      <Wizard />
    </WizardProvider>
  )
}

export default Index