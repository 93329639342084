import React from 'react';
import DOMPurify from 'dompurify';

interface SafeHtmlProps {
  html: string;
}

const SafeHtml: React.FC<SafeHtmlProps> = ({ html }) => {
  const cleanHtml = DOMPurify.sanitize(html);
  return <div dangerouslySetInnerHTML={{ __html: cleanHtml }} />;
}

export default SafeHtml;
