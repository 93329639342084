import React from 'react';
import IsNotLoggedIn from './IsNotLoggedIn';
import Guide from './Guide';
import { useAuth } from '../../../contexts/AuthContext'; // Adjust the path if needed

const Index = () => {
  const { isLoggedIn, error } = useAuth();

  if (isLoggedIn === null) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;
  return (
    <div>
      {isLoggedIn ? <Guide /> : <IsNotLoggedIn />}
    </div>
  );
}

export default Index;
