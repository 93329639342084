import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  useForm,
  Controller,
  useFieldArray,
  FieldError,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { validationSchema } from "./submitLitigatorSchema";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { useLayoutContext } from "../../../contexts/LayoutContext";
import { Grid } from "react-loader-spinner";
import { toast } from "react-toastify";
import Cookies from "js-cookie";

type AddLitigatorDTO = {
  firstName: string;
  lastName: string;
  phoneNumbers: string[];
  complaintDate: Date;
  alledgedCallDate: Date;
  notes: string | null;
  fileComplaint: any;
};

const Index = () => {
  const {
    control,
    reset,
    setValue,
    handleSubmit,
    formState: { errors, isDirty, isSubmitSuccessful },
  } = useForm({
    resolver: zodResolver(validationSchema),
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "phoneNumbers",
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const onSubmit = useCallback(async (data: any) => {
    setIsLoading(true);
    console.log(data);
    const {
      firstName,
      lastName,
      phoneNumbers,
      complaintDate,
      alledgedCallDate,
      notes,
      fileComplaint,
    } = data as AddLitigatorDTO;
    const formData = new FormData();
    formData.append("FirstName", firstName);
    formData.append("LastName", lastName);

    if (phoneNumbers && phoneNumbers.length > 0) {
      phoneNumbers.forEach((phoneNumber) => {
        formData.append("PhoneNumbers", phoneNumber);
      });
    }
    formData.append("ComplaintDate", complaintDate.toISOString());
    formData.append("AlledgedCallDate", alledgedCallDate.toISOString());

    if (notes) {
      formData.append("Notes", notes);
    }

    if (fileComplaint) {
      formData.append("FileComplaint", fileComplaint);
    }
    const baseUrl = process.env.REACT_APP_API_URL;
    //TODO: change this. just to test if it's work in prod
    const apiUrl = `${baseUrl}/ComplianceGuide/AddLitigator`;
    const headers: { [key: string]: string } = {
      "Content-Type": "multipart/form-data",
    };
    const loginId = Cookies.get("loginId");
    const csrfToken = Cookies.get("csrfToken");

    if (loginId) {
      headers["loginId"] = loginId;
    }
    if (csrfToken) {
      headers["csrfToken"] = csrfToken;
    }

    axios
      .post(apiUrl, formData, {
        headers: headers,
        withCredentials: true,
      })
      .then((response) => {
        console.log("Response:", response.data);
        setIsLoading(false);
        toast.success("Successfully Submitted Litigator");
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    // note: not sure why is not working in prod

    // const apiUrl = "/ComplianceGuide/AddLitigator";
    // const response = await callApi(
    //   apiUrl,
    //   HttpMethod.POST,
    //   formData,
    //   true,
    //   "multipart/form-data"
    // );
    // if (response) {
    //   console.log(response);
    //   setIsLoading(false);
    //   toast.success("Successfully Submitted Litigator");
    // }
  }, []);

  const formatPhoneNumber = useCallback((value: any) => {
    if (!value) return value;
    const onlyNums = value.replace(/[^\d]/g, "");
    if (onlyNums.length <= 3) return `(${onlyNums}`;
    if (onlyNums.length <= 6)
      return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3)}`;
    return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 6)}-${onlyNums.slice(
      6,
      10
    )}`;
  }, []);

  useEffect(() => {
    const handleUnload = (e: BeforeUnloadEvent) => {
      if (isDirty) {
        e.preventDefault();
        e.returnValue =
          "You have unsaved changes! Are you sure you want to leave?";
      }
    };

    window.addEventListener("beforeunload", handleUnload);
    return () => window.removeEventListener("beforeunload", handleUnload);
  }, [isDirty]);

  const { setHeading, setTagline } = useLayoutContext();
  useEffect(() => {
    if (setHeading && setTagline) {
      setHeading("Add Litigator");
      setTagline(
        "Add a person who has sent you a demand letter or filed a TCPA lawsuit to our crowdsourced litigator database"
      );
    }
  }, [setHeading, setTagline]);

  useEffect(() => {
    // Append a default phone number field when the component mounts
    append("");
    reset();
  }, [append, reset]);

  useEffect(() => {
    console.log("reset");
    setValue("phoneNumbers", [{ phoneNumbers: "" }]);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    reset();
  }, [reset, isSubmitSuccessful, setValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {isLoading && (
        <div className="loader-container">
          <Grid
            height="60"
            width="60"
            color="#0eb0e5"
            ariaLabel="grid-loading"
            radius="12.5"
            wrapperStyle={{}}
            wrapperClass=""
            visible={isLoading}
          />
        </div>
      )}
      <div className="row">
        <Controller
          name="firstName"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <div className="col-md-6" style={{ padding: 10 }}>
              <div className="input-group">
                <span className="input-group-text">Litigator First Name</span>
                <input
                  {...field}
                  onChange={field.onChange}
                  type="text"
                  className={`form-control ${
                    errors.firstName ? "is-invalid" : ""
                  }`}
                />
              </div>
              {errors.firstName && (
                <p>{errors.firstName.message?.toString()}</p>
              )}
            </div>
          )}
        />
        <Controller
          name="lastName"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <div className="col-md-6" style={{ padding: 10 }}>
              <div className="input-group">
                <span className="input-group-text">Litigator Last Name</span>
                <input
                  {...field}
                  onChange={field.onChange}
                  type="text"
                  className={`form-control ${
                    errors.lastName ? "is-invalid" : ""
                  }`}
                />
              </div>
              {errors.lastName && <p>{errors.lastName.message?.toString()}</p>}
            </div>
          )}
        />
      </div>
      <>
        {fields.map((row, index) => (
          <Controller
            key={row.id}
            name={`phoneNumbers[${index}]`}
            control={control}
            defaultValue={""}
            render={({ field }) => (
              <div className="row" key={row.id}>
                <div className="col-md-6" style={{ padding: 10 }}>
                  <div className="input-group">
                    <span className="input-group-text">
                      Litigator Phone Number
                    </span>
                    <input
                      {...field}
                      onChange={(e) =>
                        field.onChange(formatPhoneNumber(e.target.value))
                      }
                      type="text"
                      className={`form-control ${
                        errors.phoneNumbers ? "is-invalid" : ""
                      }`}
                    />
                  </div>
                  {
                    ((errors.phoneNumbers as any)?.[index] as FieldError)
                      ?.message
                  }
                </div>
                <div className="col-md-4" style={{ padding: 10 }}>
                  {field.name !== "phoneNumbers[0]" && (
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={() => remove(index)}
                    >
                      Remove
                    </button>
                  )}
                </div>
                <div className="col-md-2"></div>
              </div>
            )}
          />
        ))}
        <div className="row">
          <div className="col-md-4">
            <button
              style={{ marginTop: 10 }}
              type="button"
              className="btn btn-secondary"
              onClick={() => append("")}
            >
              <i className="bi bi-plus-lg"></i>
              Add Additional Phone Number
            </button>
          </div>
          <div className="col-md-8"></div>
        </div>
      </>
      <div className="row">
        <div className="col-md-6" style={{ padding: 10 }}>
          <div className="row">
            <Controller
              name="complaintDate"
              control={control}
              defaultValue={""}
              render={({ field: { onChange, onBlur, value, name } }) => (
                <div className="col-md-12">
                  <label
                    htmlFor="complaintDate"
                    className="form-label"
                    style={{
                      fontWeight: "bold",
                      fontSize: 16,
                      paddingLeft: 5,
                    }}
                  >
                    Complaint Date
                  </label>
                  <DatePicker
                    name={name}
                    className={`form-control ${
                      errors.complaintDate ? "is-invalid" : ""
                    }`}
                    onChange={onChange}
                    onBlur={onBlur}
                    selected={value}
                    placeholderText={"MM/DD/YYYY"}
                    dateFormat="MM/dd/yyyy"
                    isClearable
                  />
                </div>
              )}
            />
            {errors.complaintDate && (
              <p>{errors.complaintDate.message?.toString()}</p>
            )}
          </div>
          <div className="row">
            <Controller
              name="alledgedCallDate"
              control={control}
              defaultValue={""}
              render={({ field: { onChange, onBlur, value, name } }) => (
                <div className="col-md-12">
                  <label
                    htmlFor="alledgedCallDate"
                    className="form-label"
                    style={{
                      fontWeight: "bold",
                      fontSize: 16,
                      paddingLeft: 5,
                      marginTop: 10,
                    }}
                  >
                    Alledged Call Date
                  </label>
                  <DatePicker
                    name={name}
                    className={`form-control ${
                      errors.alledgedCallDate ? "is-invalid" : ""
                    }`}
                    onChange={onChange}
                    onBlur={onBlur}
                    selected={value}
                    placeholderText={"MM/DD/YYYY"}
                    dateFormat="MM/dd/yyyy"
                    isClearable
                  />
                </div>
              )}
            />
            {errors.alledgedCallDate && (
              <p>{errors.alledgedCallDate.message?.toString()}</p>
            )}
          </div>
          <div className="row">
            <Controller
              defaultValue={""}
              control={control}
              name={"fileComplaint"}
              render={({ field: { value, onChange, name } }) => {
                return (
                  <div className="col-md-12">
                    <label
                      htmlFor="fileComplaint"
                      className="form-label"
                      style={{
                        fontWeight: "bold",
                        fontSize: 16,
                        paddingLeft: 5,
                        marginTop: 10,
                      }}
                    >
                      PDF Complaint or Demand Letter
                    </label>
                    <input
                      ref={fileInputRef}
                      name={name}
                      className="form-control"
                      value={value?.fileName}
                      onChange={(event: any) => {
                        onChange(event.target.files[0]);
                      }}
                      type="file"
                    />
                  </div>
                );
              }}
            />
          </div>
        </div>
        <div className="col-md-6">
          <Controller
            name="notes"
            control={control}
            defaultValue={""}
            render={({ field }) => (
              <textarea
                {...field}
                className="form-control"
                placeholder="Notes (optional)"
                style={{ height: 300 }}
              ></textarea>
            )}
          />
        </div>
      </div>
      <button type="submit" className="mt-3 w-100 btn btn-secondary">
        <i className="bi bi-save"></i> Submit
      </button>
    </form>
  );
};

export default Index;
