import React, { useEffect } from 'react';
import { ComplianceGuideProps } from '../../../../interfaces/ComplianceGuide/ComplianceGuideProps';
import SafeHtml from '../../../../components/SafeHtml';
import Citation from '../../../../components/ComplianceGuide/Citation';
import RegulatoryGuideSectionHeader from '../../../../components/ComplianceGuide/RegulatoryGuideSectionHeader';
import RegulatoryGuideSection from '../../../../components/ComplianceGuide/RegulatoryGuideSection';

const CallMonitoring: React.FC<ComplianceGuideProps> = ({
  topic,
  region,
  data,
  isCheckedHideStatesWithNoRules,
}) => {
  useEffect(() => {
    console.log(data);
    // Find the div using its ID and change its display property
    const div = document.getElementById(`conditionalRender_${region}`);
    if (div) {
      if (!isCheckedHideStatesWithNoRules) {
        div.style.display = "block"; // Or 'flex', 'grid', etc., depending on your layout requirements
      } else {
        // Check if data has a value
        if (
          data &&
          data.length > 0 &&
          (data[0] as any)?.complianceRule !== null
        ) {
          div.style.display = "block";
        } else {
          div.style.display = "none";
        }
      }
    }
  }, [data, isCheckedHideStatesWithNoRules, region]);
  if (!data || data.length === 0) {
    return <>N/A</>;
  }

  const rec = JSON.parse(data)[0];

  return (
    <>
      {isCheckedHideStatesWithNoRules && rec.complianceRule === null ? null : (
        <RegulatoryGuideSectionHeader label={`${topic} (${rec.state})`} />
      )}

      {region !== 'FCC' && region !== 'FTC' ? (
        <>
          <div className='regulatory-rules-info'>
            <div className='regulatory-contents'>
              <div className='pt-2 pb-2'>
                This is a <b>{rec.callMonParties}</b> state.
                <span>{rec.callMonNotes}</span>
              </div>

              <RegulatoryGuideSection
                label='Compliance Rule'
                value={<SafeHtml html={rec.complianceRule} />}
              />
              <RegulatoryGuideSection
                label='Citation'
                value={
                  <Citation
                    statuteId={rec.complianceRuleStatuteId}
                    statuteName={rec.complianceRuleStatuteNumber}
                    ref1={rec.complianceRuleRef1}
                    ref2={rec.complianceRuleRef2}
                    ref3={rec.complianceRuleRef3}
                  />
                }
              />
            </div>
          </div>
        </>
      ) : isCheckedHideStatesWithNoRules === true ? null : (
        <>N/A</>
      )}
    </>
  );
};

export default CallMonitoring;
