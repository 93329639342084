import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { log } from 'console';
import Cookies from 'js-cookie';

export const authApi = createApi({
    reducerPath: 'authApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL
    }),
    endpoints: (builder) => ({
        loginUser: builder.mutation({
            query: (body: { acctId: string; username: string; password: string }) => {
                return {
                    url: '/users/authenticate',
                    method: 'POST',
                    body,
                }
            },
        }),
        logoutUser: builder.mutation({
            query: () => {
                const loginId = Cookies.get('loginId');
                const csrfToken = Cookies.get('csrfToken');
                return {
                    url: '/users/logout',
                    method: 'POST',
                    headers: {
                        'loginId': loginId,
                        'csrfToken': csrfToken
                    }
                }
            },
        }),
    }),
});

export const { useLoginUserMutation, useLogoutUserMutation } = authApi;
