import React from 'react';
import { ReactComponent as ComplianceChecklistIcon } from './ComplianceChecklistIcon.svg';
import { ReactComponent as EducationIcon } from './EducationIcon.svg';
import { ReactComponent as CallDeliverabilityIcon } from './CallDeliverabilityIcon.svg';
import Styles from './GroupIcon.module.css'

type Group = string;

interface Props {
  group: Group;
}

const GroupIcon: React.FC<Props> = ({ group }) => {
  switch (group) {
    case 'Compliance Tools':
      return <ComplianceChecklistIcon className={Styles.otherToolsSvgIcon} />;
    case 'Call Deliverability':
      return <CallDeliverabilityIcon className={Styles.otherToolsSvgIcon} />;
    case 'Education and Thought Leadership':
      return <EducationIcon className={Styles.otherToolsSvgIcon} />;
    default:
      return null; 
  }
};

export default GroupIcon;
