import React, { useState, useEffect } from 'react';
import { callApi, HttpMethod } from '../../../services/apiService';
import Regions from './Regions';
import Loading from '../../../components/Loading';
import State from '../../../components/ComplianceGuide/State';
import { useLayoutContext } from '../../../contexts/LayoutContext';
import SafeHtml from '../../../components/SafeHtml';

import styles from './Guide.module.css';

interface Region {
  state: string;
  stateName: string;
}

const Guide = () => {
  const [topics, setTopics] = useState<any[]>([]);
  const [regions, setRegions] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedTopics, setSelectedTopics] = useState<
    { topicId: number; topicName: string; topicNote: string }[]
  >([]);
  const [selectedRegions, setSelectedRegions] = useState<
    { state: string; stateName: string }[]
  >([]);

  useEffect(() => {
    async function ShowTopics() {
      try {
        let apiUrl = `ComplianceGuide/Topics`;
        const fetchedData = await callApi(apiUrl, HttpMethod.GET);
        setTopics(fetchedData);
        setIsLoading(false);
      } catch (err) {
        const errorMessage = (err as Error).message;
        console.log(errorMessage);
        setIsLoading(false); // Set loading to false in case of error to not leave the user in perpetual loading state
      }
    }

    ShowTopics();
  }, []);
  const { setHeading, setTagline } = useLayoutContext();

  useEffect(() => {
    if (setHeading && setTagline) {
      setHeading('Regulatory Guide');
      setTagline('View Regulatory Rules by Topics and States');
    }
  }, [setHeading, setTagline]);

  const handleTopicCheckboxChange = (
    topicId: number,
    topicName: string,
    topicNote: string,
    isChecked: boolean
  ) => {
    if (isChecked) {
      setSelectedTopics([...selectedTopics, { topicId, topicName, topicNote }]);
    } else {
      setSelectedTopics(selectedTopics.filter((s) => s.topicId !== topicId));
    }
  };

  const handleCheckAllTopics = () => {
    const allTopicIds = topics.map((topic) => topic.id);
    setSelectedTopics(allTopicIds);
  };

  const handleUncheckAllTopics = () => {
    setSelectedTopics([]);
  };

  const topicNotes = selectedTopics
    .filter((topic) => topic.topicNote && topic.topicNote.trim() !== '')
    .map((topic) => `<b>${topic.topicName}:</b> ${topic.topicNote}`)
    .join('<br />');

  const renderContent = () => {
    if (selectedRegions.length === 0 && selectedTopics.length === 0) {
      return 'Please select a topic and state to continue.';
    } else if (selectedRegions.length === 0) {
      return 'Please select a state to continue.';
    } else if (selectedTopics.length === 0) {
      return 'Please select a topic to continue.';
    } else {
      return (
        <>
          {topicNotes && (
            <div className='alert alert-primary'>
              <SafeHtml html={topicNotes} />
            </div>
          )}
          <div className='p-3'>
            <Regions topics={selectedTopics} regions={selectedRegions} />
          </div>
        </>
      );
    }
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {((selectedTopics.length === topics.length &&
            selectedRegions.length > 8) ||
            (selectedRegions.length > 6 && selectedTopics.length >= 2)) && (
            <div className='alert-message' style={{ color: 'red' }}>
              <b>Note: this selection may take some time to load.</b>
            </div>
          )}
          {
            <div className='row w-100'>
              <div className='compliance-intro col'>
                <div className='compliance-card-container'>
                  <div className='row'>
                    <div className='col-sm-6 flex-container'>
                      <div className='compliance-card h-100 w-100'>
                        <div className='card-block'>
                          <h4 className='topic-options'>
                            View by Topic or Rule
                          </h4>
                          {/* Prohibitting check all as it hangs browser <button onClick={handleCheckAllTopics}>Check All</button>&nbsp;
                          <button onClick={handleUncheckAllTopics}>Check None</button><br /> */}

                          <div className={styles['option-select-boxes']}>
                            {topics.map((topic) => (
                              <div
                                key={topic.id}
                                className='topic-checkbox px-1'
                              >
                                <input
                                  id={topic.id.toString()}
                                  className='form-check-input'
                                  type='checkbox'
                                  value={topic.id}
                                  checked={selectedTopics.some(
                                    (t) => t.topicId === topic.id
                                  )}
                                  onChange={(e) =>
                                    handleTopicCheckboxChange(
                                      topic.id,
                                      topic.topic,
                                      topic.notes,
                                      e.target.checked
                                    )
                                  }
                                />
                                &nbsp;
                                <label
                                  className='form-check-label'
                                  htmlFor={topic.id}
                                >
                                  {topic.topic}
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='col-sm-6 flex-container states-tablet'>
                      <div className='compliance-card h-100 w-100'>
                        <div className='card-block'>
                          <h4 className='topic-options'>
                            View by State or Region
                          </h4>
                          <div
                            className={
                              styles['option-select-box-style-second-div']
                            }
                          >
                            <State
                              onStatesChange={setSelectedRegions}
                              selectedStates={selectedRegions}
                              onStatesFetchedCount={(count) =>
                                console.log(`Fetched ${count} states`)
                              }
                              initialFetchBehavior='all'
                              styleStateWrapper={styles['option-select-boxes']}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <br />

                  <div className='regulatory-guide-section'>
                    {renderContent()}
                  </div>
                </div>
              </div>
            </div>
          }
        </>
      )}
    </>
  );
};

export default Guide;
