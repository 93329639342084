import React from 'react';
import { Link, useNavigate  } from 'react-router-dom';

import { toast } from 'react-toastify'
import { useAppSelector, useAppDispatch } from '../app/hooks'
import { selectAuth, logout } from '../features/authSlice'
import { useLogoutUserMutation } from '../services/authApi';

type DropdownItem = {
    label: string;
    href: string;
    isActive?: boolean;
};

type NavDropdown = {
    label: string;
    href?: string;  // Made optional since some entries don't have href
    items: DropdownItem[];
};

type NavBarProps = {
    dropdowns: NavDropdown[];
    user: {
        name: string;
        role: string;
        username: string;
        iconSrc: string;
    };
};

const NavBar: React.FC<NavBarProps> = ({ dropdowns, user }) => {
    const { name } = useAppSelector(selectAuth);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [logoutUser] = useLogoutUserMutation();

    const handleLogout = async () => {
        try {
            await logoutUser({});  // call the logout API
            dispatch(logout());
            toast.success('Logout successful');
            navigate('/login');
        } catch (error) {
            toast.error('Failed to log out');
        }
    }
    
    return (
        <nav className="navbar navbar-expand-lg navbar-background">
            <div className="container-fluid">
                <a className="navbar-brand logo p-0" href="index.html">
                    <img alt="DNCScrub logo" className="logo" src="/images/DNC-Logo-White.svg" />
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#DNCScrub-navbar">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="DNCScrub-navbar">
                    <ul className="navbar-nav me-auto nav-pills me-auto mb-2 mb-lg-0 mobile-marg25 menu-position" role="tablist">
                        {dropdowns.map((dropdown, index) => (
                            <li key={index} className="nav-item" role="presentation">
                                {dropdown.items.length > 0 ? (
                                    <React.Fragment>
                                        <Link
                                            aria-current="page"
                                            aria-expanded="false"
                                            className="nav-link dropdown-toggle"
                                            data-bs-toggle="dropdown"
                                            to={dropdown.href || "#"}
                                            role="button"
                                        >
                                            {dropdown.label}
                                        </Link>
                                        <ul className="dropdown-menu">
                                            {dropdown.items.map((item, i) => (
                                                <React.Fragment key={i}>
                                                    <li className="nav-item">
                                                        <Link className="dropdown-item" to={item.href}>{item.label}</Link>
                                                    </li>
                                                    {i !== dropdown.items.length - 1 && <li><hr className="dropdown-divider" /></li>}
                                                </React.Fragment>
                                            ))}
                                        </ul>
                                    </React.Fragment>
                                ) : (
                                    <Link className="nav-link" to={dropdown.href || "#"}>{dropdown.label}</Link>  // Direct link if no dropdown items
                                )}
                            </li>
                        ))}
                    </ul>
                    <ul className="d-flex">
                        <li className="d-flex nav-item user-status-icon nav-padding right-position-nav push-right">
                            <Link to="#"><img alt="useradmin icon" src={user.iconSrc} /></Link>
                        </li>
                        <li className="d-flex nav-item nav-padding status-icon-position right-position-nav push-right">
                            <Link to="#" className="username-account">
                                {name}<br />
                                {user.role}<br />
                                ({user.username})
                            </Link>
                        </li>
                        <li className="d-flex nav-item nav-padding right-position-nav push-right">
                            <div onClick={handleLogout}>
                                <Link className="nav-link logout-mobile" to="/">Logout </Link>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default NavBar;
