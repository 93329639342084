import React from 'react'
import { Session } from "../../../interfaces/Session"

interface SessionCardProps {
    session: Session;
}

const SessionCard: React.FC<SessionCardProps> = ({ session }) => {
  return (
    <>
            <div className="row marg25">
                <div className="col-12">
                    <div>
                        <div>
                            <div className="status-card-completed card text-center">
                                <div className="card-header">
                                    <div className="row align-items-start">
                                        <div
                                            className="col-4 text-start text-truncate truncated-text"
                                        >
                                            <i className="status-completed bi bi-filetype-csv"></i>
                                            {session.originalFilename}
                                        </div>

                                        <div className="col-6">
                                            uploaded {session.statusLastChanged} by
                                            <i className="bi bi-person-fill"></i>&nbsp;  {session.lastUserToModifyByName}
                                        </div>

                                        <div className="col-1 upload-method-status">{session.status}</div>
                                    </div>
                                </div>

                                <div className="card-body">
                                    <div className="row align-items-start border-bottom m-0">
                                        <div
                                            className="col-1 text-start p-1 status-bar status-position"
                                        >
                                            Status:
                                        </div>

                                        <div className="col-2 not-status">
                                            <i className="bi bi-upload status-icon not-status"></i
                                            ><span className="icon-space">Importing</span>
                                        </div>

                                        <div className="col-2 not-status">
                                            <i
                                                className="bi bi-clock-history not-status status-icon"
                                            ></i
                                            ><span className="icon-space">Queued</span>
                                        </div>

                                        <div className="col-2 not-status">
                                            <i
                                                className="bi bi-clipboard2-check status-icon not-status"
                                            ></i
                                            ><span className="icon-space">Scrubbing</span>
                                        </div>

                                        <div className="col-3 not-status">
                                            <i
                                                className="bi bi-ui-checks-grid not-status status-icon"
                                            ></i
                                            ><span className="icon-space">Generating Output</span>
                                        </div>

                                        <div className="col-2 status-completed">
                                            <i
                                                className="bi bi-check-circle-fill status-icon status-completed"
                                            ></i
                                            ><span className="icon-space">Completed</span>
                                        </div>
                                    </div>

                                    <div className="row top-margin completed-status-card">
                                        <div className="col-2 text-start">
                                            Total Records:<br />
                                            Billable Phone Numbers:<br />
                                            Malformed Records:<br />
                                            VoIP:
                                        </div>

                                        <div className="col text-start">
                                            {session.numOfTotalRecords}<br />
                                            {session.numOfBillableRecords}<br />
                                            {session.numOfMalformedRecords}<br />
                                            {session.numOfVoIPRecords}
                                        </div>

                                        <div className="col-2 border-start text-start">
                                            Clean:<br />
                                            Invalid:<br />
                                            Blocked:
                                        </div>

                                        <div className="col text-start">
                                        {session.numOfCleanRecords}<br />
                                        {session.numOfInvalidRecords}<br />
                                        {session.numOfBlockedRecords}
                                        </div>

                                        <div className="col-2 border-start text-start">
                                            EBR:<br />
                                            Wireless:<br />
                                            Do Not Call:<br />
                                        </div>

                                        <div className="col text-start">
                                        {session.numOfEBRRecords}<br />
                                            {session.numOfWirelessRecords}<br />
                                            {session.numOfDNCRecords}
                                        </div>

                                        <div className="col-3 border-start text-start">
                                            <button
                                                className="download-file-available"
                                                type="button"
                                            >
                                                DOWNLOAD OUTPUT FILE</button
                                            ><br />
                                            <span className="download-history"
                                            >Last downloaded: 4/19/2023 11:11:11<br />
                                                by
                                                <i className="bi bi-person-plus-fill"></i>
                                                {session.outputFileLastDownloadedByName}</span
                                            >

                                            <div className="col text-start">
                                                <button
                                                    className="btn btn-danger btn-sm delete-top-margin"
                                                >
                                                    Delete
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="card-footer">
                                    <div className="row align-items-start">
                                        <div className="col accordion">
                                            <div className="accordion-item">
                                                <h4 className="accordion-header">
                                                    <button
                                                        aria-controls="show-details"
                                                        aria-expanded="false"
                                                        className="accordion-button show-hide-details collapsed"
                                                        data-bs-target="#show-details-scrubcard2"
                                                        data-bs-toggle="collapse"
                                                        type="button"
                                                    >
                                                        Show Details
                                                    </button>
                                                </h4>

                                                <div
                                                    className="accordion-collapse collapse"
                                                    data-bs-parent="#scrubDetails"
                                                    id="show-details-scrubcard2"
                                                >
                                                    <div className="accordion-body col">
                                                        Last User to make an Action on this
                                                        session:&nbsp;
                                                        <i className="bi bi-person-plus-fill"></i>&nbsp; {session.lastUserToModifyByName}
                                                        <span className="col"
                                                        >&nbsp; &nbsp; Time it Took to Perform Last Scrub: {session.timeLastScrubInSecs}</span
                                                        >
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
  )
}

export default SessionCard