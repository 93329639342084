import React from 'react';

interface IframeProps {
    title: string;
    src: string;
    width?: string | number;
    height?: string | number;
    style?: React.CSSProperties;
    allowFullScreen?: boolean;
}

const IframeComponent: React.FC<IframeProps> = ({
    title,
    src,
    width,
    height,
    style,
    allowFullScreen = false,
    ...restProps
}) => {
    const iframeProps: any = {
        title,
        src,
        style,
        allowFullScreen,
        ...restProps
    };

    if (width) {
        iframeProps.width = width;
    }

    if (height) {
        iframeProps.height = height;
    }

    return <iframe {...iframeProps} />;
};

export default IframeComponent;
