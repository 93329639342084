import React, { useEffect } from 'react';

// Auto login if a developer
const setDeveloperCookie = () => {
  // Comment-out when you want to not be logged in
  document.cookie =
    "loginId=010ED2D2C537044393787480E440A286832AB0519600; Domain=.dncscrub.com; Path=/";
};

const SetCookieIfDeveloper: React.FC = () => {
  useEffect(() => {
    if (process.env.REACT_APP_ENV === "development") {
      setDeveloperCookie();
    }
  }, []); // Empty dependency array ensures it runs only once after the initial render

  return null;
}

export default SetCookieIfDeveloper;
