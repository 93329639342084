import React from 'react'

function Footer() {
    return (
        <footer className="container footer-behavior">
            <div className="footer">
                <h5>© 2023 - Contact Center Compliance</h5>
            </div>
        </footer>
    )
}

export default Footer