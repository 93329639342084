import React, { useState, useEffect } from 'react';
import { callApi, HttpMethod } from '../../../../services/apiService';
import RegulatoryGuideSectionHeader from '../../../../components/ComplianceGuide/RegulatoryGuideSectionHeader';
import Loading from '../../../../components/Loading';
import SafeHtml from '../../../../components/SafeHtml';
import RegulatoryGuideSection from '../../../../components/ComplianceGuide/RegulatoryGuideSection';

type HolidayAdditionalInfo = {
  notes: string;
  citations: string;
};
type HolidayParams = {
  region: string;
  isCheckedIncludePreviousYearHolidays: boolean;
  isCheckedHideStatesWithNoRules: boolean;
};
const HolidayRestrictions: React.FC<HolidayParams> = ({
  region,
  isCheckedIncludePreviousYearHolidays,
  isCheckedHideStatesWithNoRules,
}) => {
  const [data, setData] = useState<any>(null);
  const [additionalData, setAdditionalData] = useState<HolidayAdditionalInfo>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    // Find the div using its ID and change its display property
    const div = document.getElementById(`conditionalRender_${region}`);
    if (div) {
      if (!isCheckedHideStatesWithNoRules) {
        div.style.display = 'block'; // Or 'flex', 'grid', etc., depending on your layout requirements
      } else {
        // Check if data has a value
        if (
          data &&
          data.length > 0 &&
          (data[0] as any)?.complianceRule !== null
        ) {
          div.style.display = 'block';
        } else {
          div.style.display = 'none';
        }
      }
    }
  }, [data, isCheckedHideStatesWithNoRules, region]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let apiUrl = `complianceguide/holidays?region=${region}&includePreviousYearHoliday=${isCheckedIncludePreviousYearHolidays}`;
        const response = await callApi(apiUrl, HttpMethod.GET);
        setData(response);
      } catch (err) {
        if (err instanceof Error) {
          setError(err.message);
        } else {
          setError('An unknown error occurred');
        }
      } finally {
        setLoading(false);
      }
    };
    const fetchAdditionalHolidayInfo = async () => {
      try {
        let apiUrl = `complianceguide/holidayAdditionalInfo?state=${region}`;
        const response: HolidayAdditionalInfo = await callApi(
          apiUrl,
          HttpMethod.GET
        );
        setAdditionalData(response);
      } catch (err) {
        if (err instanceof Error) {
          setError(err.message);
        } else {
          setError('An unknown error occurred');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    fetchAdditionalHolidayInfo();
  }, [isCheckedIncludePreviousYearHolidays, region]);

  if (loading) return <Loading />;
  if (error) return <div>Error: {error}</div>;

  const formatDate = (day: string) => {
    const date = new Date(day);

    // Get the day, month, and year
    const dd = String(date.getDate()).padStart(2, '0');
    const mm = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
    const yyyy = date.getFullYear();

    return `${mm}/${dd}/${yyyy}`;
  };

  return (
    <>
      {region === 'FTC' ? (
        <div className='regulatory-contents'>
          {'See FCC entry for Federal holiday listing'}
        </div>
      ) : data && data.length > 0 ? (
        <>
          <RegulatoryGuideSectionHeader
            label={`Holiday Restrictions for (${region})`}
          />
          <div>Upcoming state holidays with restricted calling:</div>
          <table className='table table-border content-width-table'>
            <tbody>
              {data.map((record: any, index: number) => (
                <tr key={index}>
                  <td>{formatDate(record.day)}</td>
                  <td>{record.name}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <RegulatoryGuideSection
            label='Holiday Notes for'
            value={<SafeHtml html={additionalData?.notes!} />}
          />
          <RegulatoryGuideSection
            label='Holiday Citation for'
            value={<SafeHtml html={additionalData?.citations!} />}
          />
        </>
      ) : isCheckedHideStatesWithNoRules === true ? null : (
        <>
          <RegulatoryGuideSectionHeader
            label={`Holiday Restrictions for (${region})`}
          />
          <div className='regulatory-contents'>
            {'No state rule for calling time restrictions on holidays.'}
          </div>
        </>
      )}
    </>
  );
};

export default HolidayRestrictions;
