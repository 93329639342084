// Importing the CSS this way gets it minified by the build tool
import '../css/App.css';

import React from 'react';
import { Helmet } from 'react-helmet-async';
import Header from '../components/Header';
import Footer from '../components/Footer';
import PrivateRoute from '../components/PrivateRoute';

type SiteLayoutProps = {
    children: React.ReactNode;
};

const SiteLayout: React.FC<SiteLayoutProps> = ({ children }) => {
    return (
        <PrivateRoute>
            <Helmet>
                <title>DNCScrub</title>
                <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css" rel="stylesheet" />
                <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/js/bootstrap.bundle.min.js" />
                <link href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css" rel="stylesheet" />
            </Helmet>

            <Header />
            <div className="container-fluid d-inline-flex d-none d-lg-flex" id="pagetop">
                <div className="marg150">
                    {children}
                </div>
            </div>
            <Footer />
            <script src="./js/site.js" />
        </PrivateRoute>
    );
};

export default SiteLayout;
