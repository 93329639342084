import { useState, useEffect, useCallback, useRef } from 'react';

const useInactivity = (timeoutDuration: number): boolean => {
  const [inactive, setInactive] = useState<boolean>(false);
  const inactivityTimer = useRef<ReturnType<typeof setTimeout> | null>(null);

  const resetTimer = useCallback((): void => {
    if (inactivityTimer.current) clearTimeout(inactivityTimer.current);
    setInactive(false);
    inactivityTimer.current = setTimeout(() => {
      setInactive(true);
    }, timeoutDuration);
  }, [timeoutDuration]);

  useEffect(() => {
    window.addEventListener('mousemove', resetTimer);
    window.addEventListener('keydown', resetTimer);
    window.addEventListener('click', resetTimer);
    window.addEventListener('scroll', resetTimer);

    resetTimer();  // Start the timer when the hook is used

    return () => {
      // Cleanup
      window.removeEventListener('mousemove', resetTimer);
      window.removeEventListener('keydown', resetTimer);
      window.removeEventListener('click', resetTimer);
      window.removeEventListener('scroll', resetTimer);
      if (inactivityTimer.current) {
        clearTimeout(inactivityTimer.current);
      }
    };
  }, [resetTimer]);

  return inactive;
};

export default useInactivity;
