import React, { useEffect, useRef, useState } from 'react';
import { useLayoutContext } from '../../../contexts/LayoutContext';

const Index = () => {
    const vizRef = useRef<HTMLObjectElement | null>(null);
    const [vizWidth, setVizWidth] = useState<string>('100%');
    const [vizHeight, setVizHeight] = useState<string>('1027px');
    const { setHeading, setTagline } = useLayoutContext();
    
    useEffect(() => {
      if (setHeading && setTagline) {
        setHeading('Regulatory Watch');
        setTagline('Track new legislation and regulatory changes');
      }
    }, [setHeading, setTagline]);

    useEffect(() => {
      const handleResize = () => {
        if (vizRef.current) {
          const width = vizRef.current.offsetWidth;
  
          if (width > 800 || width > 500) {
            setVizWidth('1000px');
            setVizHeight('827px');
          } else {
            setVizWidth('100%');
            setVizHeight('1027px');
          }
        }
      };
      handleResize();
  
      window.addEventListener('resize', handleResize);
  
      const scriptElement = document.createElement('script');
      scriptElement.src = 'https://public.tableau.com/javascripts/api/viz_v1.js';
  
      if (vizRef.current) {
        vizRef.current.appendChild(scriptElement);
      }
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
  
    return (
      <>
        <div className="tableauPlaceholder" id="viz1648482721550" style={{ position: 'relative' }} ref={vizRef}>
          <noscript>
            <a href="#">
              <img
                alt="Priority Bills"
                src="https://public.tableau.com/static/images/PA/PACE_16460786706590/PriorityBills/1_rss.png"
                style={{ border: 'none' }}
              />
            </a>
          </noscript>
          <object className="tableauViz" style={{ display: 'none', width: vizWidth, height: vizHeight }}>
            <param name="host_url" value="https%3A%2F%2Fpublic.tableau.com%2F" />
            <param name="embed_code_version" value="3" />
            <param name="site_root" value="" />
            <param name="name" value="PACE_16460786706590/PriorityBills" />
            <param name="tabs" value="no" />
            <param name="toolbar" value="yes" />
            <param name="static_image" value="https://public.tableau.com/static/images/PA/PACE_16460786706590/PriorityBills/1.png" />
            <param name="animate_transition" value="yes" />
            <param name="display_static_image" value="yes" />
            <param name="display_spinner" value="yes" />
            <param name="display_overlay" value="yes" />
            <param name="display_count" value="yes" />
            <param name="language" value="en-US" />
            <param name="filter" value="publish=yes" />
          </object>
        </div>
      </>
    );
}

export default Index