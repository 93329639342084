import React, { useState } from 'react';
import { useWizard } from '../../../contexts/ComplianceGuide/ComplianceWizardContext';
import CWQuestion from './CWQuestion';

const Step1: React.FC = () => {
    const { answers, setAnswers, markStepAsComplete } = useWizard();
    const [previousCallDirection, setPreviousCallDirection] = useState(answers["callDirection"]);

    const handleAnswerChange = (column: string, value: any) => {
        let updatedAnswers = { ...answers, [column]: value };

        // Mark step as complete/incomplete logic
        const areQuestionsAnswered = ["callDirection"].every(key => updatedAnswers[key] && updatedAnswers[key] !== "");
        if (areQuestionsAnswered) {
            markStepAsComplete(1, true);
        } else {
            markStepAsComplete(1, false);
        }

        // Reset answers logic
        if (column === "callDirection" && previousCallDirection !== value) {
            ['inquiryEBR', 'transactionEBR', 'permissionEBR', 'prize', 'monitoring', 'predictiveDialer', 'prerecorded', 'outboundUpsell', 'inboundUpsell'].forEach((key) => {
                delete updatedAnswers[key];
            });
            setPreviousCallDirection(value);
        }

        setAnswers(updatedAnswers);
    };

    return (
        <div>
            <CWQuestion
                column="callDirection"
                value={answers["callDirection"] || ""}
                onChange={handleAnswerChange}
            />
            {/*<CWQuestion
                column="size"
                value={answers["size"] || ""}
                onChange={handleAnswerChange}
                selectedStates={answers.selectedStates || []}
            />
            */}
        </div>
    );
};

export default Step1;
