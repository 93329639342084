import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import Cookies from 'js-cookie';
import { DataReceiverProps } from '../../../interfaces/DataReceiverProps';

interface UploadFileProps extends DataReceiverProps<string> { }

const UploadFile: React.FC<UploadFileProps> = ({ dataFromSender }) => {
    const campaignId = dataFromSender;

    const onDrop = useCallback((acceptedFiles: File[]) => {
        const file = acceptedFiles[0];

        const formData = new FormData();
        formData.append('campaignId', campaignId);
        formData.append('handler', 'scrub_upload');
        formData.append('CSRFToken', Cookies.get('csrfToken') + '');
        formData.append('files[]', file);

        // Send the request
        axios({
            method: 'post',
            url: 'https://www.dncscrub.com/app/main/XFileCallbackPOST',  // Replace with your remote endpoint
            data: formData,
            withCredentials: true  // This will ensure cookies are sent with the request
        })
        .then(response => {
            console.log('File uploaded:', response.data);
        })
        .catch(error => {
            console.error('There was an error uploading the file:', error);
        });
    }, [ campaignId ]);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        multiple: false
    });

    return (
        <div>
            Selected Campaign: {dataFromSender}<br /><br />
            <div {...getRootProps()} style={{border: '1px solid gray', padding: '20px', cursor: 'pointer'}}>
                <input {...getInputProps()} />
                Drag & drop a file here, or click to select one
            </div>
            <button onClick={() => onDrop([])}>Upload Data</button>
        </div>
    );
}

export default UploadFile