import React, { useEffect, useState } from "react";
import { callApi, HttpMethod } from "../../services/apiService";
import { Project } from "../../interfaces/Project";
import { Campaign } from "../../interfaces/Campaign";
import Cookies from 'js-cookie';

interface TreeViewProps {
  onCampaignClick: (campaign: Campaign) => void;
}

const TreeView: React.FC<TreeViewProps> = ({ onCampaignClick }) => {
  const [projects, setProjects] = useState<Project[]>([]);
  const [campaigns, setCampaigns] = useState<Record<string, Campaign[]>>({});
  const [expandedProjectIds, setExpandedProjectIds] = useState<string[]>([]);
  const acctId = Cookies.get('acctId');

  useEffect(() => {
    // Fetch all projects
    async function fetchProjects() {
      const data = await callApi(`projects/getprojects?acctid=${acctId}`, HttpMethod.GET);
      setProjects(data);
      fetchDefaultCampaign(data[0].projId);
    }

    fetchProjects();
  }, []);

  const handleProjectClick = async (projectId: string) => {
    if (expandedProjectIds.includes(projectId)) {
      setExpandedProjectIds(prev => prev.filter(id => id !== projectId));
    } else {
      const fetchedCampaigns = await callApi(`campaigns/getcampaigns?projectId=${projectId}`, HttpMethod.GET);
      setCampaigns(prevCampaigns => ({ ...prevCampaigns, [projectId]: fetchedCampaigns }));
      setExpandedProjectIds(prev => [...prev, projectId]);
      fetchDefaultCampaign(projectId);
    }
  };

  const fetchDefaultCampaign = async (projId: string) => {
    if(projId === undefined) return;
    try {
      const apiUrl = `campaigns/getdefaultcampaignid?acctId=${acctId}&projId=${projId}`;
      const response = await callApi(apiUrl, HttpMethod.GET);
      const defaultCampaign: Campaign = {
        id: response, 
        name: 'Default Campaign'
      };
      onCampaignClick(defaultCampaign);

    } catch (error) {
      console.error('Error fetching campaigns:', error);
    }
  };

  const handleAddNewCampaignClick = (projectId: string) => {
    // Here you can handle the logic for adding a new campaign to a project.
    // This might involve opening a modal, form, or navigating to another page.
  };

  return (
    <div>
      {projects.map(project => (
        <div key={project.projId}>
          <div onClick={() => handleProjectClick(project.projId)}>
            {project.name}
          </div>
          {expandedProjectIds.includes(project.projId) && (
            <>
              {campaigns[project.projId]?.map((campaign: Campaign) => (
                <div key={campaign.id} style={{ marginLeft: 20 }} onClick={() => onCampaignClick(campaign)}>
                  {campaign.name}
                </div>
              ))}
              <div style={{ marginLeft: 20, color: 'blue', cursor: 'pointer' }} onClick={() => handleAddNewCampaignClick(project.projId)}>
                Add new Campaign
              </div>
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default TreeView;
