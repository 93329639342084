import { useLocation } from "react-router-dom";
import React, { CSSProperties } from "react";

type Badge = {
  badgName: string;
  color: string;
};

type MenuItem = {
  id: number;
  title: string;
  url: string;
  icon: string;
  activePage: boolean;
  badge?: Badge;
};

// Use Indexed Access Type to make code more readible and DRY
// https://www.typescriptlang.org/docs/handbook/2/indexed-access-types.html
type BadgeLookup = {
  [key: string]: Badge;
};

const navLinkStyle: CSSProperties = {
  whiteSpace: "nowrap",
  paddingLeft: "0.85rem",
  paddingRight: "0.85rem",
  display: "inline-block",
};

// As the navbar is repeated twice for a mobile and non-mobile version,
// we define a component just used by this page to show it
const MenuList: React.FC<{ items: MenuItem[] }> = ({ items }) => (
  <ul className="navbar-nav me-auto mb-2 mb-lg-0 nav-position-tablet">
    {items.map((item) => (
      <li key={item.id} className="nav-item">
        <a
          style={navLinkStyle}
          className={`nav-link subnav d-flex align-items-center ${
            item.activePage ? `disabled current-page` : `subnav-link`
          }`}
          href={item.url}
        >
          <i
            className={`bi ${item.icon} subnav-icon ${
              item.activePage ? `active-page` : ``
            }`}
          />
          {item.title}
          {item.badge && (
            <span className={`badge ${item.badge.color}`}>
              {item.badge.badgName}
            </span>
          )}
        </a>
      </li>
    ))}
  </ul>
);

const CGNavBar = () => {
  // Define the badge pills we can have next to navbar items
  const badgeLookup: BadgeLookup = {
    New: { badgName: `New`, color: `badge-primary` },
    Updated: { badgName: `Updated`, color: `badge-success` },
  };

  // Define our navbar
  const baseURL = `/compliance-guide/`;
  const menuItemsDef: MenuItem[] = [
    {
      id: 1,
      title: `Intro`,
      url: `${baseURL}intro`,
      activePage: false,
      icon: `bi-file-binary`,
    },
    {
      id: 2,
      title: `Guide`,
      url: `${baseURL}regulatory-guide`,
      activePage: false,
      icon: `bi-bank`,
    },
    {
      id: 3,
      title: `Charts`,
      url: `${baseURL}regulatory-charts`,
      activePage: false,
      icon: `bi-bar-chart-line`,
    },
    {
      id: 4,
      title: `Regulatory Watch`,
      url: `${baseURL}regulatory-watch`,
      activePage: false,
      icon: `bi-eye`,
      badge: badgeLookup.New,
    },
    {
      id: 5,
      title: `DNC Maps`,
      url: `${baseURL}dnc-map`,
      activePage: false,
      icon: `bi-globe`,
    },
    {
      id: 6,
      title: `Statutes`,
      url: `${baseURL}statutes`,
      activePage: false,
      icon: `bi-receipt`,
    },
    {
      id: 7,
      title: `FAQ`,
      url: `${baseURL}faq`,
      activePage: false,
      icon: `bi-patch-question`,
    },
    {
      id: 8,
      title: `Compliance Wizard`,
      url: `${baseURL}compliance-wizard`,
      activePage: false,
      icon: `bi-magic`,
    },
    // {
    //   id: 9,
    //   title: `Exemption Master`,
    //   url: `${baseURL}exemption-master`,
    //   activePage: false,
    //   icon: `bi-list-columns`,
    // },
    {
      id: 10,
      title: `Glossary`,
      url: `${baseURL}glossary`,
      activePage: false,
      icon: `bi-book`,
    },
    {
      id: 11,
      title: `Litigator Research`,
      url: `${baseURL}litigator-research`,
      activePage: false,
      icon: `bi-clipboard2-check`,
    },
    {
      id: 15,
      title: `Litigator Scrub`,
      url: `${baseURL}litigator-scrub`,
      activePage: false,
      icon: `bi bi-table`,
    },
    {
      id: 12,
      title: `FTC Complaint Search`,
      url: `${baseURL}ftc-complaint-search`,
      activePage: false,
      icon: `bi-search`,
    },
    {
      id: 13,
      title: `Submit Litigator`,
      url: `${baseURL}submit-litigator`,
      activePage: false,
      icon: `bi-envelope`,
    },
    {
      id: 14,
      title: `Other Tools`,
      url: `${baseURL}other-tools`,
      activePage: false,
      icon: `bi-tools`,
    },
  ];

  // Set the active nav bar item based on the current URL
  const location = useLocation();
  const menuItems = menuItemsDef.map((item) => ({
    ...item,
    activePage: location.pathname === item.url,
  }));

  // We want the nav bar to be right up top with no whitespace
  const navBarStyle: CSSProperties = {
    top: "0.5rem",
  };

  return (
    <aside
      style={navBarStyle}
      className="compliance-guide-subnav"
      id="page-sidenav"
    >
      {/* First navbar is overlay for narrower viewports */}
      <div
        aria-labelledby="sideNavMenuLabel"
        className="offcanvas offcanvas-start"
        id="sideNavMenu"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="sideNavMenuLabel">
            Compliance Guide Menu
          </h5>
          <button
            aria-label="Close"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            type="button"
          />
        </div>
        <span className="divider" />
        <nav className="offcanvas-body">
          <MenuList items={menuItems} />
        </nav>
      </div>

      {/* Second navbar is for desktop viewports */}
      <nav className="d-flex flex-column flex-shrink-0 p-2 mt-2">
        <MenuList items={menuItems} />
      </nav>
    </aside>
  );
};

export default CGNavBar;
