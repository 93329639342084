// LoginLayout.tsx
import React from 'react';
import { Helmet } from 'react-helmet-async';

type LoginLayoutProps = {
    children: React.ReactNode;
};

const LoginLayout: React.FC<LoginLayoutProps> = ({ children }) => {
    return (
        <>
            <Helmet>
                <title>Login</title>
                <link rel="stylesheet" href="/css/login.css" />
            </Helmet>
            <div className="login-page">
                {children}
            </div>
        </>
    );
};

export default LoginLayout;
