import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import IframeComponent from './IframeComponent';

const Tcl: React.FC = () => {
    const [urls] = useState<string[]>([
        'https://www.dncscrub.com/app/report/r-policies-sent.adp',
        'https://www.dncscrub.com/app/users/u-admin.adp?add=0',
        'https://core.dncscrub.com/app/trustcall/monitor',
        'https://www.dncscrub.com/complianceInfo/',
        'https://core.dncscrub.com/app/trustcall/monitor',
        'https://core.dncscrub.com/app/litigator/search/index',
        'https://js.dncscrub.com/app/trustcall',
        'https://core.dncscrub.com/app/sysadmin/salesproductionreport/index'
    ]);

    const [titles] = useState<string[]>([
        'Reports',
        'Users',
        'Help',
        'Compliance Guide',
        'TrustCall Monitor',
        'Litigator Research',
        'TrustCall Lookup',
        'Sales Report'
    ]);

    const params = useParams();
    const indexAsString = params.index;

    if (!indexAsString) {
        return <div>Unauthorized</div>;
    }

    const indexAsNumber = parseInt(indexAsString, 10);

    if (indexAsNumber > 7) {
        return <div>Unauthorized</div>;
    }

    const src = urls[indexAsNumber];
    const title = titles[indexAsNumber];

    console.log('src', src);
    if (isNaN(indexAsNumber)) {
        return <div>Unauthorized</div>;
    }

    if (isNaN(indexAsNumber)) {
        return <div>Unauthorized</div>;
    };

    return (
        <IframeComponent
            title={title}
            src={src}
            style={{
                position: "absolute",
                left: "0",
                width: "100%",
                height: 'calc(100vh - 75px)',
                border: "0"
            }}
            allowFullScreen
        ></IframeComponent>
    )
}

export default Tcl
